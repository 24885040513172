<template>
    <footer
    id="footer"
    class="bxs-footer">
        <bxs-layout
        fluid
        class="light">
            <div class="pt-12 mb-ui">
                <p class="h2 mb-0">La tua prossima spedizione con fiducia</p>
            </div>

            <bxs-row>
                <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
                -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <bxs-col cols="12" ds="6">
                    <bxs-row :class="!$store.state.is_mobile ? 'mb-12' : ''">
                        <bxs-col cols="12" ds="6">
                            <div class="mb-ui">
                                <router-link
                                to="/"
                                class="text-tertiary text-uppercase mb-ui link-mute">Fusco Express Sagl</router-link> <br>
                                <a href="tel:+41919402335" class="mb-1">(+41) 91 940 23 35</a> <br>
                                <a href="https://maps.app.goo.gl/yHFByX5xAUfEaTY56" target="_blank" class="mb-1">Stabile Vedeggio, Via della Posta 34</a> <br>
                                <p class="mb-0">6934 Bioggio, Ticino (Svizzera)</p>
                            </div>

                            <div class="mt-6">
                                <a
                                href="tel:+41919402335"
                                class="text-mute link-mute">
                                    <bxs-icon
                                    name="phone-fill"
                                    width="20"
                                    height="20"
                                    class="mr-6"></bxs-icon>
                                </a>

                                <a
                                href="https://maps.app.goo.gl/yHFByX5xAUfEaTY56"
                                target="_blank"
                                class="text-mute link-mute">
                                    <bxs-icon
                                    name="geo-fill"
                                    width="20"
                                    height="20"
                                    class="mr-6"></bxs-icon>
                                </a>

                                <a
                                href="https://www.instagram.com/fusco.ch/"
                                target="_blank"
                                class="text-mute link-mute">
                                    <bxs-icon
                                    name="ig"
                                    width="20"
                                    height="20"
                                    class="mr-6"></bxs-icon>
                                </a>

                                <a
                                href="https://www.facebook.com/FUSCOEXPRESS/"
                                target="_blank"
                                class="text-mute link-mute">
                                    <bxs-icon
                                    name="fb"
                                    width="20"
                                    height="20"
                                    class="mr-6"></bxs-icon>
                                </a>

                                <a
                                href="https://www.linkedin.com/company/fusco-express-sagl/about/"
                                target="_blank"
                                class="text-mute link-mute">
                                    <bxs-icon
                                    name="li"
                                    width="20"
                                    height="20"></bxs-icon>
                                </a>
                            </div>
                        </bxs-col>

                        <bxs-col cols="12" ds="6">
                            <div :class="!$store.state.is_mobile ? 'mb-12' : 'mb-ui'">
                                <p class="text-tertiary text-uppercase mb-4">Email logistica</p>
                                <a href="mailto:info@fusco.ch" class="mb-0">info@fusco.ch</a>
                            </div>

                            <div :class="!$store.state.is_mobile ? 'mb-12' : 'mb-ui'">
                                <p class="text-tertiary text-uppercase mb-4">Email amministrazione</p>
                                <a href="mailto:admin@fusco.ch" class="mb-0">admin@fusco.ch</a>
                            </div>

                            <div>
                                <p class="text-tertiary text-uppercase mb-4">Email sales</p>
                                <a href="mailto:sales@fusco.ch" class="mb-0">sales@fusco.ch</a>
                            </div>
                        </bxs-col>
                    </bxs-row>
                </bxs-col>

                <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
                -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
                <bxs-col cols="12" ds="6">
                    <bxs-row>
                        <bxs-col cols="12" ds="4">
                            <div>
                                <p class="text-tertiary text-uppercase mb-ui">Esplora</p>
                                <ul class="text-uppercase">
                                    <li>
                                        <router-link
                                        v-if="$route.name !== 'index'"
                                        to="/">Servizi di trasporto</router-link>

                                        <a
                                        v-else
                                        @click="scrollTo('section-services')">Servizi di trasporto</a>
                                    </li>
                                    <!-- <li class="mb-2">
                                        <router-link to="/assistenza-clienti">Assistenza clienti</router-link>
                                    </li> -->
                                    <!-- <li>
                                        <router-link to="/news">News</router-link>
                                    </li> -->
                                </ul>
                            </div>
                        </bxs-col>

                        <bxs-col cols="12" ds="4">
                            <div>
                                <p class="text-tertiary text-uppercase mb-ui">Azienda</p>
                                <ul class="text-uppercase">
                                    <li class="mb-1">
                                        <router-link
                                        v-if="$route.name !== 'index'"
                                        to="/">Chi siamo</router-link>

                                        <a
                                        v-else
                                        @click="scrollTo('section-about')">Chi siamo</a>
                                    </li>
                                    <!-- <li class="mb-2">
                                        <router-link to="/team">Team</router-link>
                                    </li> -->
                                    <!-- <li class="mb-2">
                                        <router-link to="/lavora-con-noi">Lavora con noi</router-link>
                                    </li> -->
                                    <li>
                                        <router-link
                                        v-if="$route.name !== 'index'"
                                        to="/">Contatti</router-link>

                                        <a @click="scrollTo('footer')">Contatti</a>
                                    </li>
                                </ul>
                            </div>
                        </bxs-col>

                        <bxs-col cols="12" ds="4">
                            <div>
                                <p class="text-tertiary text-uppercase mb-ui">Legal</p>
                                <ul class="text-uppercase">
                                    <li class="mb-1">
                                        <router-link to="/privacy-policy">Privacy Policy</router-link>
                                    </li>
                                    <li class="mb-1">
                                        <router-link to="/cookie-policy">Cookie Policy</router-link>
                                    </li>
                                    <li>
                                        <a
                                        role="button"
                                        @click="$emit('click:cookie-settings')">Cookie Settings</a>
                                    </li>
                                </ul>
                            </div>
                        </bxs-col>
                    </bxs-row>
                </bxs-col>
            </bxs-row>

            <!--  -->
            <bxs-row>
                <bxs-col cols="12" ds="6" dm="3">
                    <p class="text-tertiary text-uppercase mb-ui small">CONNECTING WITH GREEN</p>
                    <p class="small text-mute mb-ui">Optando per i nostri servizi di trasporto, contribuisci attivamente a un futuro più verde. Sostieni l'ambiente scegliendo una soluzione di trasporto sostenibile ed eco-friendly. Insieme, possiamo fare la differenza per le generazioni future.</p>
                    <bxs-figure src="/img/green.png" max-width="120"></bxs-figure>
                </bxs-col>
            </bxs-row>
        </bxs-layout>

        <bxs-divider></bxs-divider>

        <!-- --------------------------------------------------------------------------------------------------------------------------------------------------------
        -------------------------------------------------------------------------------------------------------------------------------------------------------- -->
        <bxs-layout
        fluid
        class="fourth text-mute py-ui my-0">
            <div class="flex nowrap align-center">
                <small class="flex-1">© {{ new Date().getFullYear() }} Fusco Express Sagl. All rights reserved. CHE-113.025.984</small>

                <a
                v-if="!$store.state.is_mobile"
                href="https://simonefusco.com"
                target="_blank">
                    <small>Credits</small>
                </a>
            </div>
        </bxs-layout>

        <grid />
    </footer>
</template>

<script>
export default {
    name: 'bxs_footer',
    methods: {
        scrollTo (v) {
            this.$root.scroll.scrollTo('#' + v)
        }
    }
}
</script>

<style lang="scss" scoped>
.bxs-footer {
    position: relative;
    width: 100%;
    min-height: var(--footer-min-height);
    background-color: var(--footer-background-color);
    color: var(--footer-color);
    z-index: var(--layer-navbar);
    font-size: var(--footer-font-size);
    transition: transform 0.5s ease-out;
}
</style>