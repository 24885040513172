<template>
  <div class="index--view pt-0">
    <hero
    src="/img/street.svg"
    centered>
      <!--
        src-center="/img/street.svg"
        src-right="/img/courier.jpeg"
      -->
      <grid />

      <bxs-layout>
        <bxs-row align="center">
          <!-- offset-ds="3" -->
          <bxs-col
          cols="12"
          ds="9"
          dl="6"
          offset-dl="3">
            <div>
              <p
              v-if="!$store.state.is_mobile"
              class="text-uppercase text-medium">
                <small>Oltre la Svizzera</small>
              </p>
              <!-- <h1>Riduciamo le distanze che vi <br> separano da ciò che desiderate</h1>
              <p><router-link to="/servizi-di-spedizione">Trasporti</router-link> rapidi e sicuri per il mercato commerciale e privato.</p> -->
              <h1>Trasporti rapidi per il mercato commerciale e privato</h1>
              <p>Abbiamo costruito un canale di connessione per diminuire le distanze che vi separano da ciò che desiderate.</p>
              <!-- <bxs-btn append-icon="chevron-compact-right">I servizi</bxs-btn> -->
              <btn @click="$root.scroll.scrollTo('#section-services')">Servizi di spedizione</btn>
            </div>
          </bxs-col>

          <bxs-col
          v-if="!$store.state.is_mobile"
          cols="3">
            <bxs-figure
            ratio="2-3"
            src="/img/courier.jpeg"></bxs-figure>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </hero>

    <!-- ------------------------------------------------------------------------------------------------------------------------------
      about
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <section id="section-about">
      <grid />

      <div class="px-layout py-ui text-primary mb-ui">
        <small class="text-uppercase">Chi siamo</small>
      </div>

      <!--  -->
      <bxs-layout v-if="!$store.state.is_mobile">
        <bxs-row>
          <bxs-col cols="12" ds="3">
            <bxs-figure
            ratio="2-3"
            src="/img/park.jpeg" />
          </bxs-col>

          <bxs-col cols="12" ds="6">
            <div class="mb-12">
              <h3>Creiamo sistemi di trasporto efficienti e sostenibili che migliorano la vita delle persone.</h3>
              <p>Con una profonda esperienza e competenze tecniche specialistiche, <br> abbiamo fornito servizi di trasporto, supportando le consegne di oltre 10 miliardi.</p>
            </div>

            <bxs-row>
              <bxs-col cols="12" ds="6">
                <h6 class="text-primary">Miglioriamo con l'approfondimento</h6>
                <p>Nell’arco di vent’anni l’impresa a conduzione familiare si è costantemente ampliata aumentando i propri servizi ed il proprio organico occupando attualmente 50 dipendenti. Una realtà guidata dall'armonia del team e dal continuo sguardo verso l'innovazione. Lo sguardo che ci permette di essere in sinergia con i nostri clienti e di evolvere insieme alla richiesta di mercato.</p>
              </bxs-col>

              <bxs-col cols="12" ds="6">
                <h6 class="text-primary">Focalizzati sui risultati</h6>
                <p>Con la nostra costante attenzione ai risultati, ci impegniamo a fondo per comprendere il vostro problema, le principali parti interessate e i contensti più ampi. Ogni singolo chilometro che percorriamo in territorio nazionale e internazione, con particolare attenzioneper le città e le valli di tutto il Canton Ticino, porta con sè affidabilità, puntualità e cura. Passo dopo passo con tenacia e determinazione abbiamo costruito un canale di connessione per diminuire le distanze che vi separano da ciò che desiderate.</p>
              </bxs-col>
            </bxs-row>

            <!-- <div>
              <btn to="/servizi-di-spedizione">Scopri di più</btn>
            </div> -->

            <bxs-row>
              <bxs-col
              cols="6"
              ds="3"
              class="text-center">
                <p class="h1 mb-1">20+</p>
                <p class="text-tertiary text-uppercase mb-0">Anni di esperienza</p>
              </bxs-col>

              <bxs-col
              cols="6"
              ds="3"
              class="text-center">
                <p class="h1 mb-1">50+</p>
                <p class="text-tertiary text-uppercase mb-0">Dipendenti</p>
              </bxs-col>

              <bxs-col
              cols="6"
              ds="3"
              class="text-center">
                <p class="h1 mb-1">50</p>
                <p class="text-tertiary text-uppercase mb-0">Veicoli</p>
              </bxs-col>

              <bxs-col
              cols="6"
              ds="3"
              class="text-center">
                <div>
                  <p class="h1 mb-1">1000+</p>
                  <p class="text-tertiary text-uppercase mb-0">Spedizioni giornaliere</p>
                </div>
              </bxs-col>
            </bxs-row>
          </bxs-col>

          <bxs-col cols="12" ds="3">
            <bxs-figure
            ratio="2-3"
            src="/img/teams/owners.jpeg" />
          </bxs-col>
        </bxs-row>
      </bxs-layout>

      <!--  -->
      <bxs-layout v-else>
        <div class="mb-12">
          <h4>La nostra visione è quella di creare sistemi di trasporto efficienti e sostenibili che migliorino la qualità della vita delle persone.</h4>
          <p>Con una profonda esperienza e competenze tecniche specialistiche, abbiamo fornito servizi di trasporto, supportando le consegne di oltre 10 miliardi.</p>
        </div>

        <bxs-figure
        ratio="2-3"
        src="/img/park.jpeg"
        class="mb-ui" />

        <div class="mb-ui">
          <div class="mb-ui">
            <h6 class="text-primary">Miglioriamo con l'approfondimento</h6>
            <p>Nell’arco di vent’anni l’impresa a conduzione familiare si è costantemente ampliata aumentando i propri servizi ed il proprio organico occupando attualmente 50 dipendenti. Una realtà guidata dall'armonia del team e dal continuo sguardo verso l'innovazione. Lo sguardo che ci permette di essere in sinergia con i nostri clienti e di evolvere insieme alla richiesta di mercato.</p>
          </div>

          <div>
            <h6 class="text-primary">Focalizzati sui risultati</h6>
            <p>Con la nostra costante attenzione ai risultati, ci impegniamo a fondo per comprendere il vostro problema, le principali parti interessate e i contensti più ampi. Ogni singolo chilometro che percorriamo in territorio nazionale e internazione, con particolare attenzioneper le città e le valli di tutto il Canton Ticino, porta con sè affidabilità, puntualità e cura. Passo dopo passo con tenacia e determinazione abbiamo costruito un canale di connessione per diminuire le distanze che vi separano da ciò che desiderate.</p>
          </div>
        </div>

        <bxs-figure
        ratio="2-3"
        src="/img/teams/owners.jpeg"
        class="mb-ui" />

        <!-- <div class="mt-ui">
          <btn to="/servizi-di-spedizione">Scopri di più</btn>
        </div> -->
      </bxs-layout>
    </section>

    <!-- ------------------------------------------------------------------------------------------------------------------------------
      numeri
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <!-- <section>
      <bxs-layout>
        <bxs-row>
          <bxs-col
          cols="6"
          ds="3"
          class="text-center">
            <p class="h1">20+</p>
            <p class="text-uppercase">Anni di esperienza</p>
          </bxs-col>

          <bxs-col
          cols="6"
          ds="3"
          class="text-center">
            <p class="h1">50+</p>
            <p class="text-uppercase">Dipendenti</p>
          </bxs-col>

          <bxs-col
          cols="6"
          ds="3"
          class="text-center">
            <p class="h1">50</p>
            <p class="text-uppercase">Veicoli</p>
          </bxs-col>

          <bxs-col
          cols="6"
          ds="3"
          class="text-center">
            <div>
              <p class="h1">1000+</p>
              <p class="text-uppercase">Spedizioni giornaliere</p>
            </div>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section> -->

    <!-- ------------------------------------------------------------------------------------------------------------------------------
      servizi di trasporto
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <section
    id="section-services"
    class="primary light">
      <!-- <grid /> -->

      <!-- <div class="px-layout py-ui mb-ui">
        <small class="text-uppercase">Servizi di trasporto</small>
      </div> -->

      <bxs-layout>
        <bxs-row>
          <bxs-col cols="12" ds="3">
            <small class="text-uppercase">Servizi di trasporto</small>
            <!-- <arrow /> -->
          </bxs-col>

          <bxs-col
          cols="12"
          ds="5">
            <div class="mb-12">
              <h3>Trasporti rapidi e sicuri</h3>
              <p>Le tue spedizioni a destinazione nel minor tempo possibile.</p>
            </div>

            <ul class="mb-12">
              <li
              v-for="(item, i) in services"
              :key="item.id"
              class="mb-2 mb-0-last">
                <p
                :class="['service-item', 'h1', 'text-medium', 'mb-0', 'bxs-pointer', {
                  'service-item-actived': i === current_service
                }]"
                @click="current_service = i"
                @mouseenter="current_service = i">{{ item.title }}</p>
              </li>
            </ul>

            <div>
              <p>{{ services[current_service].desc }}</p>
              <a
              :href="`/pdf/${services[current_service].pdf}`"
              target="_blank">
                <btn light>Scopri {{ services[current_service].title }} in dettaglio</btn>
              </a>
            </div>
          </bxs-col>

          <bxs-col cols="12" ds="4">
            <bxs-figure
            ratio="3-2"
            src="/img/courier.jpeg"></bxs-figure>
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section>

    <!-- ------------------------------------------------------------------------------------------------------------------------------
      assistenza clienti
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <!-- <section class="customer-service--section">
      <grid />

      <div class="pa-ui">
        <small class="text-uppercase">Assistenza clienti</small>
      </div>

      <bxs-layout>
        <bxs-row>
          <bxs-col cols="3">
            col 1
          </bxs-col>

          <bxs-col cols="6">
            <h4>Lorem ipsum dolor sit amet consectetur adipisicing elit.</h4>
            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Tempore placeat incidunt dolorem facilis aperiam eum sed cum, qui perferendis autem inventore aliquam blanditiis non impedit doloribus optio explicabo sit. Tenetur?</p>
            <btn to="/contatti">Hai bisogno di assistenza?</btn>
          </bxs-col>

          <bxs-col cols="3">
            col 3
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section> -->

    <!-- ------------------------------------------------------------------------------------------------------------------------------
      clienti
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <!-- <section class="secondary light">
      <grid />

      <div class="pa-ui">
        <small class="text-uppercase">Clienti</small>
      </div>

      <bxs-layout>
        <bxs-row>
          <bxs-col cols="3">
            col 1
          </bxs-col>

          <bxs-col cols="4">
            col 3
          </bxs-col>

          <bxs-col cols="3">
            col 3
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section> -->

    <!-- ------------------------------------------------------------------------------------------------------------------------------
      news
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <!-- <section>
      <grid />

      <div class="pa-ui">
        <small class="text-uppercase">News</small>
      </div>

      <bxs-layout>
        <bxs-row>
          <bxs-col cols="3">
            <btn to="/servizi-di-spedizione/sameday">Vedi tutte</btn>
          </bxs-col>

          <bxs-col cols="4">
            <p class="small">Celebrating International Women’s Day 07/03/2024</p>
            <bxs-figure
            ratio="3-2"
            src="/img/test-2.webp" />
          </bxs-col>

          <bxs-col cols="2">
            <p class="small">Engaging Client Conversations 05/03/2024</p>
            <bxs-figure
            ratio="3-2"
            src="/img/test-2.webp" />
          </bxs-col>

          <bxs-col cols="2">
            <p class="small">Team Activity @ Crockd Studios 01/03/2024</p>
            <bxs-figure
            ratio="3-2"
            src="/img/test-2.webp" />
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section> -->

    <!-- ------------------------------------------------------------------------------------------------------------------------------
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <!-- <section class="tertiary">
      <grid />

      <div class="pa-ui">
        <small class="text-uppercase">Lavora con noi</small>
      </div>

      <bxs-layout>
        <bxs-row>
          <bxs-col cols="3">
            col 1
          </bxs-col>

          <bxs-col cols="4">
            col 3
          </bxs-col>

          <bxs-col cols="3">
            col 3
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section> -->

    <!-- ------------------------------------------------------------------------------------------------------------------------------
      newsletter
    ------------------------------------------------------------------------------------------------------------------------------ -->
    <!-- <section class="tertiary">
      <grid />

      <div class="pa-ui">
        <small class="text-uppercase">Newsletter</small>
      </div>

      <bxs-layout>
        <bxs-row>
          <bxs-col cols="3">
            col 1
          </bxs-col>

          <bxs-col cols="4">
            col 3
          </bxs-col>

          <bxs-col cols="3">
            col 3
          </bxs-col>
        </bxs-row>
      </bxs-layout>
    </section> -->
  </div>
</template>

<script>
export default {
  name: 'index',
  head () {
    return {
      title: 'Fusco Express',
      meta: [
        { name: 'description', content: '' },
        { name: 'article:modified_time', content: '' },
        // og
        { name: 'og:type', content: '' },
        { name: 'og:site_name', content: '' },
        { name: 'og:title', content: '' },
        { name: 'og:description', content: '' },
        { name: 'og:image', content: '' },
        { name: 'og:url', content: window.location.href },
        // twc
        { name: 'twitter:card', content: '' },
        { name: 'twitter:title', content: '' },
        { name: 'twitter:description', content: '' },
        { name: 'twitter:image', content: '' },
        // indexing
        { name: 'robots', content: '' }
      ],
      script: [
        { type: 'application/ld+json', content: '' }
      ]
    }
  },
  data () {
    return {
      current_service: 0,
      services: [
        {
          id: 1,
          title: 'Sameday',
          desc: 'Le vostre spedizioni a destinazione il giorno stesso.',
          pdf: 'fusco-express-servizi-tariffe.pdf'
        },
        {
          id: 2,
          title: 'Nextday',
          desc: 'Le vostre spedizioni a destinazione il giorno seguente.',
          pdf: 'fusco-express-servizi-tariffe.pdf'
        },
        {
          id: 3,
          title: 'Dedicato',
          desc: 'Servizio veicoli commerciali con autista.',
          pdf: 'fusco-express-servizi-tariffe.pdf'
        },
        {
          id: 4,
          title: 'Apostille',
          desc: 'Legalizzazione documenti presso la cancelleria di Stato.',
          pdf: 'fusco-express-servizi-tariffe.pdf'
        },
        {
          id: 5,
          title: 'Noleggio',
          desc: 'Servizio noleggio veicoli trasporto merci con o senza autista.',
          pdf: 'fusco-express-servizi-trasporto-merci.pdf'
        }
      ]
    }
  }
}
</script>
