export default {
    props: {
        rounded: {
            type: Boolean,
            required: false,
            default: false
        },
        pill: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        roundable_classes() {
            return {
                'bxs-rounded': this.rounded && !this.pill,
                'bxs-pill': this.pill && !this.rounded
            }
        }
    }
}
