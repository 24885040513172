/* eslint-disable */

export function isInViewport(el) {
    const rect = el.getBoundingClientRect()
    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
}

export function fixVh () {
    const vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty("--vh", `${vh}px`)
    return `${vh}px`
}

export const getCSSVar = (name) => getComputedStyle(document.documentElement).getPropertyValue(name)

export function getBreakPoint() {
    let device = window.getComputedStyle(document.body, '::after').getPropertyValue('content')
    device = device.replace(/('|")/g, '')
    const deviceSplitted = device.split('-')
    return {
        name: String(deviceSplitted[0]),
        value: parseInt(String(deviceSplitted[1]).replace('px', ''))
    }
}

export function getBreakpointsMap() {
    let mapString = getComputedStyle(document.head).getPropertyValue('font-family')
    mapString = mapString.replace("/map-to-JSON((", "");
    mapString = mapString.replace("))/", "");
    const mapArray = mapString.split(",");
    const map = new Map();

    mapArray.forEach(b => {
        const s = b.split(":");
        map.set(
            s[0].replace(/["]/gi, "").trim(),
            parseInt(s[1].replace("px", ""), 10)
        );
    });
    return map;
}

export const isMobile = () => getBreakPoint().value <= getBreakpointsMap().get('m')

export const rootVarExist = (name) => getComputedStyle(document.documentElement).getPropertyValue(name) !== ''

export const getRootVar = (name) => getComputedStyle(document.documentElement).getPropertyValue(name)

export function getBrowser () {
    const ua = navigator.userAgent
    let tem
    let match = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []

    if (/trident/i.test(match[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || []
        return 'IE ' + (tem[1] || '')
    }

    if (match[1] === 'Chrome') {
        tem = ua.match(/\b(OPR|Edge)\/(\d+)/)
        if (tem !== null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
    }

    match = match[2] ? [match[1], match[2]] : [navigator.appName, navigator.appVersion, '-?']
    if ((tem = ua.match(/version\/(\d+)/i)) !== null) match.splice(1, 1, tem[1])

    return {
        name: match[0].toLocaleLowerCase(),
        version: match[1],
        versionValue: parseFloat(match[1])
    }
}

export const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1

export const isSafari = () => {
    const ua = navigator.userAgent.toLowerCase()
    let isSafari = false
    isSafari = /constructor/i.test(window.HTMLElement) || (p => p.toString() === '[object SafariRemoteNotification]')(!window.safari || window.safari.pushNotification)
    isSafari = (isSafari || ((ua.indexOf('safari') !== -1) && (!(ua.indexOf('chrome') !== -1) && (ua.indexOf('version/') !== -1))))
    return isSafari
}

export const isTouchDevice = () => window.matchMedia('(pointer: coarse) and (hover: none)').matches

export const isEdge = () => {
    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10)
    }
    const trident = ua.indexOf('Trident/')
    if (trident > 0) {
        // IE 11 => return version number
        const rv = ua.indexOf('rv:')
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10)
    }
    const edge = ua.indexOf('Edge/')
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10)
    }
    return false
}

export function parseURL(url) {
    const parser = document.createElement('a')
    const searchObject = {}
    let split
    let i
    const protocol = process.env.NODE_ENV === 'development' ? 'http' : 'https'

    parser.href = url
    const queries = parser.search.replace(/^\?/, '').split('&')

    for (i = 0; i < queries.length; i++) {
        split = queries[i].split('=')
        searchObject[split[0]] = split[1]
    }

    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        subdomain: url.split('.')[0].replace(`${protocol}://`, ''),
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash
    }
}

export default {}