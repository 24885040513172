<template>
    <span
    :class="['bxs-icon', {
        'bxs-icon-disabled': !!disabled,
        'bxs-disabled': !!disabled,
        ...routable_classes,
        ...colorable.classes
    }]"
    :style="measurable_styles"
    @click="routableHandleClick">
        <svg
        ref="svg"
        xmlns="http://www.w3.org/2000/svg"
        fill="currentColor"
        viewBox="0 0 16 16">
            <path
            v-if="name"
            :d="$store.state.icons[name]" />

            <slot />
        </svg>
    </span>
</template>

<script>
import { routable, measurable, colorable } from '@/mixins'
// import { fixSvg } from '@/assets/libs/utils/vector'

export default {
    name: 'bxs-icon',
    mixins: [routable, measurable, colorable],
    props: {
        name: {
            type: String,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    mounted () {
        this.$nextTick(() => {
            setTimeout(() => {
                // fixSvg(this.$refs.svg)
            }, 200)
        })
    }
}
</script>

<style lang="scss" scoped>
.bxs-icon {
    position: relative;
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    width: var(--icon-size);
    height: var(--icon-size);

    > svg {
        width: 100%;
        height: 100%;
        // margin-top: -1px;
        // transform: translateY(-2px);
    }

    &.bxs-icon-disabled {
    }
}
</style>