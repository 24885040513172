<template>
    <div class="bxs-big-menu">
        <h6>I'm big menu</h6>
    </div>
</template>

<script>
export default {
    name: 'bxs_big_menu'
}
</script>