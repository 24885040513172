import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'
// import {
//   // auth,
//   nextFactory
// } from './middlewares'

import Error404 from '../views/Err404.vue'
import Maintenance from '../views/Maintenance.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import CookiePolicy from '../views/CookiePolicy.vue'

import Index from '../views/Index.vue'
import About from '../views/About.vue'
import Services from '../views/Services.vue'
import Contacts from '../views/Contacts.vue'
import Page from '../views/Page.vue'
// import Blog from '../views/Blog.vue'
// import BlogPost from '../views/BlogPost.vue'

const routes = [
  // static routes
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/azienda',
    name: 'about',
    component: About
  },
  {
    path: '/servizi',
    name: 'services',
    component: Services
  },
  {
    path: '/contatti',
    name: 'contacts',
    component: Contacts
  },
  {
    path: '/privacy-policy',
    name: 'privacy_policy',
    component: PrivacyPolicy
  },
  {
    path: '/cookie-policy',
    name: 'cookie_policy',
    component: CookiePolicy
  },
  // {
  //   path: '/blog',
  //   name: 'blog',
  //   component: Blog
  // },
  // {
  //   path: '/blog/:slug',
  //   name: 'post_blog',
  //   component: BlogPost
  // },
  // dynamic routes
  {
    path: '/:slug',
    name: 'page',
    component: Page
  },
  //
  {
    path: '/errors/maintenance',
    name: 'error_maintenance',
    component: Maintenance
  },
  {
    path: '/errors/404',
    name: 'error_404',
    component: Error404
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/errors/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
  // scrollBehavior(to, from, savedPosition) {
  //   return { top: 0 }
  // }
})

router.beforeEach((to, from, next) => {
  console.log('router external beforeEach()', to)

  store.commit('setPageEnter', to.name)
  store.commit('setPageLeave', from && from.name ? from.name : null)

  // if (to.meta.middlewares) {
  //   const middlewares = Array.isArray(to.meta.middlewares) ? to.meta.middlewares : [to.meta.middlewares]
  //   const context = { from, to, next }
  //   const nextMiddleware = nextFactory(context, middlewares, 1)
  //   const auths = to.meta.authorize ? to.meta.authorize : []
  //   return middlewares[0](auths, { ...context, next: nextMiddleware })
  // }

  return next()
})

export default router
