<template>
    <div class="privacy-policy--view policy--view">
        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Privacy Policy</h5>
                    <!-- <bxs-logo
                    src="/img/logo-color.png" /> -->
                    <p>Fusco Express Sagl</p>
                    <p>{{ new Date().getMonth() }}/{{ new Date().getFullYear() }}</p>
                </div>

                <p>Questa Applicazione raccoglie alcuni Dati Personali dei propri Utenti.</p>
                <p>Questo documento contiene <a href="#fadp_info" target="_self">una sezione dedicata agli Utenti in Svizzera e ai loro diritti alla privacy</a>. </p>
                <p>Questo documento può essere stampato utilizzando il comando di stampa presente nelle impostazioni di qualsiasi browser.</p>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Titolare del Trattamento dei Dati</h5>
                </div>

                <div class="block">
                    <p class="mb-1">Fusco Express Sagl</p>
                    <p class="mb-1">Stabile Vedeggio, Via della Posta 34</p>
                    <p class="mb-1">6934 Bioggio, Ticino (Svizzera)</p>
                    <p class="mb-1">CHE-113.025.984</p>
                    <p><b>Indirizzo email del Titolare:</b> <a href="mailto:info@fusco.ch">info@fusco.ch</a></p>
                </div>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Tipologie di Dati raccolti</h5>
                </div>

                <div class="block">
                    <p> Fra i Dati Personali raccolti da questa Applicazione, in modo autonomo o tramite terze parti, ci sono: email; Strumenti di Tracciamento; Dati di utilizzo; nome; cognome. </p>
                    <p>Dettagli completi su ciascuna tipologia di Dati Personali raccolti sono forniti nelle sezioni dedicate di questa privacy policy o mediante specifici testi informativi visualizzati prima della raccolta dei Dati stessi.</p>
                    <p>I Dati Personali possono essere liberamente forniti dall'Utente o, nel caso di Dati di Utilizzo, raccolti automaticamente durante l'uso di questa Applicazione.</p>
                    <p>Se non diversamente specificato, tutti i Dati richiesti da questa Applicazione sono obbligatori. Se l’Utente rifiuta di comunicarli, potrebbe essere impossibile per questa Applicazione fornire il Servizio. Nei casi in cui questa Applicazione indichi alcuni Dati come facoltativi, gli Utenti sono liberi di astenersi dal comunicare tali Dati, senza che ciò abbia alcuna conseguenza sulla disponibilità del Servizio o sulla sua operatività.</p>
                    <p>Gli Utenti che dovessero avere dubbi su quali Dati siano obbligatori sono incoraggiati a contattare il Titolare. <br> L’eventuale utilizzo di Cookie - o di altri strumenti di tracciamento - da parte di questa Applicazione o dei titolari dei servizi terzi utilizzati da questa Applicazione ha la finalità di fornire il Servizio richiesto dall'Utente, oltre alle ulteriori finalità descritte nel presente documento e nella Cookie Policy.</p>
                    <p>L'Utente si assume la responsabilità dei Dati Personali di terzi ottenuti, pubblicati o condivisi mediante questa Applicazione.</p>
                </div>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Modalità e luogo del trattamento dei Dati raccolti</h5>
                </div>

                <div class="block">
                    <h6>Modalità di trattamento </h6>
                    <p>Il Titolare adotta le opportune misure di sicurezza volte ad impedire l’accesso, la divulgazione, la modifica o la distruzione non autorizzate dei Dati Personali. <br> Il trattamento viene effettuato mediante strumenti informatici e/o telematici, con modalità organizzative e con logiche strettamente correlate alle finalità indicate. Oltre al Titolare, in alcuni casi, potrebbero avere accesso ai Dati altri soggetti coinvolti nell’organizzazione di questa Applicazione (personale amministrativo, commerciale, marketing, legali, amministratori di sistema) ovvero soggetti esterni (come fornitori di servizi tecnici terzi, corrieri postali, hosting provider, società informatiche, agenzie di comunicazione) nominati anche, se necessario, Responsabili del Trattamento da parte del Titolare. L’elenco aggiornato dei Responsabili potrà sempre essere richiesto al Titolare del Trattamento. </p>
                </div>

                <div class="block">
                    <h6>Luogo</h6>
                    <p>I Dati sono trattati presso le sedi operative del Titolare ed in ogni altro luogo in cui le parti coinvolte nel trattamento siano localizzate. Per ulteriori informazioni, contatta il Titolare. <br> I Dati Personali dell’Utente potrebbero essere trasferiti in un paese diverso da quello in cui l’Utente si trova. Per ottenere ulteriori informazioni sul luogo del trattamento l’Utente può fare riferimento alla sezione relativa ai dettagli sul trattamento dei Dati Personali.</p>
                </div>

                <div class="block">
                    <h6>Periodo di conservazione</h6>
                    <p>Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti. </p>
                </div>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <h5>Finalità del Trattamento dei Dati raccolti</h5>
                <p> I Dati dell’Utente sono raccolti per consentire al Titolare di fornire il Servizio, adempiere agli obblighi di legge, rispondere a richieste o azioni esecutive, tutelare i propri diritti ed interessi (o quelli di Utenti o di terze parti), individuare eventuali attività dolose o fraudolente, nonché per le seguenti finalità: Contattare l'Utente e Statistica. </p>
                <p>Per ottenere informazioni dettagliate sulle finalità del trattamento e sui Dati Personali trattati per ciascuna finalità, l’Utente può fare riferimento alla sezione “Dettagli sul trattamento dei Dati Personali”.</p>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <h5>Dettagli sul trattamento dei Dati Personali</h5>
                <p>I Dati Personali sono raccolti per le seguenti finalità ed utilizzando i seguenti servizi:</p>

                <bxs-row>
                    <bxs-col cols="12" ds="6">
                        <div class="block">
                            <h6>Contattare l'Utente</h6>
                        </div>

                        <div class="block">
                            <p><b>Mailing list o newsletter (questa Applicazione)</b></p>
                            <p>Con la registrazione alla mailing list o alla newsletter, l’indirizzo email dell’Utente viene automaticamente inserito in una lista di contatti a cui potranno essere trasmessi messaggi email contenenti informazioni, anche di natura commerciale e promozionale, relative a questa Applicazione. L'indirizzo email dell'Utente potrebbe anche essere aggiunto a questa lista come risultato della registrazione a questa Applicazione o dopo aver effettuato un acquisto.</p>
                            <p>Dati Personali trattati: email.</p>
                        </div>

                        <div class="block">
                            <p><b>Modulo di contatto (questa Applicazione)</b></p>
                            <p>L’Utente, compilando con i propri Dati il modulo di contatto, acconsente al loro utilizzo per rispondere alle richieste di informazioni, di preventivo, o di qualunque altra natura indicata dall’intestazione del modulo.</p>
                            <p>Dati Personali trattati: cognome; email; nome.</p>
                        </div>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <div class="block">
                            <h6>Statistica</h6>
                        </div>

                        <p>I servizi contenuti nella presente sezione permettono al Titolare del Trattamento di monitorare e analizzare i dati di traffico e servono a tener traccia del comportamento dell’Utente.</p>

                        <div class="block">
                            <p><b>Google Analytics (Universal Analytics)</b></p>
                            <p>Google Analytics (Universal Analytics) è un servizio di analisi web fornito da Google LLC oppure da Google Ireland Limited, a seconda di come il Titolare gestisce il trattamento dei Dati, (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google.</p>
                            <p>Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario.</p>
                            <p>Per una comprensione dell'utilizzo dei dati da parte di Google, si prega di consultare le <a href="https://www.google.com/intl/it/policies/privacy/partners/" target="_blank" rel="noopener">norme per i partner di Google</a>. </p>
                            <p>Dati Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.</p>
                            <p>Luogo del trattamento: Stati Uniti – <a href="https://business.safety.google/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=it" onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=it'})" target="_blank">Opt Out</a>; Irlanda – <a href="https://business.safety.google/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=en" onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})" target="_blank">Opt Out</a>. </p>
                        </div>

                        <div class="block">
                            <p><b>Google Analytics (Universal Analytics) con IP anonimizzato</b></p>
                            <p>Google Analytics (Universal Analytics) è un servizio di analisi web fornito da Google LLC oppure da Google Ireland Limited, a seconda di come il Titolare gestisce il trattamento dei Dati, (“Google”). Google utilizza i Dati Personali raccolti allo scopo di tracciare ed esaminare l’utilizzo di questa Applicazione, compilare report e condividerli con gli altri servizi sviluppati da Google. <br> Google potrebbe utilizzare i Dati Personali per contestualizzare e personalizzare gli annunci del proprio network pubblicitario. <br> Questa integrazione di Google Analytics rende anonimo il tuo indirizzo IP. L'anonimizzazione funziona abbreviando entro i confini degli stati membri dell'Unione Europea o in altri Paesi aderenti all'accordo sullo Spazio Economico Europeo l'indirizzo IP degli Utenti. Solo in casi eccezionali, l'indirizzo IP sarà inviato ai server di Google ed abbreviato all'interno degli Stati Uniti. </p>
                            <p>Per una comprensione dell'utilizzo dei dati da parte di Google, si prega di consultare le <a href="https://www.google.com/intl/it/policies/privacy/partners/" target="_blank" rel="noopener">norme per i partner di Google</a>. </p>
                            <p>Dati Personali trattati: Dati di utilizzo; Strumenti di Tracciamento.</p>
                            <p>Luogo del trattamento: Stati Uniti – <a href="https://business.safety.google/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=it" onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=it'})" target="_blank">Opt Out</a>; Irlanda – <a href="https://business.safety.google/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a> – <a href="https://tools.google.com/dlpage/gaoptout?hl=en" onclick="javascript:return tryFunc('tryGaOptOut',{href:'https://tools.google.com/dlpage/gaoptout?hl=en'})" target="_blank">Opt Out</a>. </p>
                        </div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <h5>Cookie Policy</h5>
                <p>Questa Applicazione fa utilizzo di Strumenti di Tracciamento. Per saperne di più, gli Utenti possono consultare la <router-link to="/cookie-policy">Cookie Policy</router-link>.</p>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Ulteriori informazioni per gli utenti</h5>
                </div>

                <div class="block">
                    <h6>Base giuridica del trattamento</h6>
                    <p>Il Titolare tratta Dati Personali relativi all’Utente in caso sussista una delle seguenti condizioni:</p>
                    <ul>
                        <li>l’Utente ha prestato il consenso per una o più finalità specifiche.</li>
                        <li>il trattamento è necessario all'esecuzione di un contratto con l’Utente e/o all'esecuzione di misure precontrattuali;</li>
                        <li>il trattamento è necessario per adempiere un obbligo legale al quale è soggetto il Titolare;</li>
                        <li>il trattamento è necessario per l'esecuzione di un compito di interesse pubblico o per l'esercizio di pubblici poteri di cui è investito il Titolare;</li>
                        <li>il trattamento è necessario per il perseguimento del legittimo interesse del Titolare o di terzi.</li>
                    </ul>
                    <p>È comunque sempre possibile richiedere al Titolare di chiarire la concreta base giuridica di ciascun trattamento ed in particolare di specificare se il trattamento sia basato sulla legge, previsto da un contratto o necessario per concludere un contratto.</p>
                </div>

                <div class="block">
                    <h6>Ulteriori informazioni sul tempo di conservazione</h6>
                    <p>Se non diversamente indicato in questo documento, i Dati Personali sono trattati e conservati per il tempo richiesto dalla finalità per la quale sono stati raccolti e potrebbero essere conservati per un periodo più lungo a causa di eventuali obbligazioni legali o sulla base del consenso degli Utenti. </p>
                    <p>Pertanto:</p>
                    <ul>
                        <li>I Dati Personali raccolti per scopi collegati all’esecuzione di un contratto tra il Titolare e l’Utente saranno trattenuti sino a quando sia completata l’esecuzione di tale contratto.</li>
                        <li>I Dati Personali raccolti per finalità riconducibili all’interesse legittimo del Titolare saranno trattenuti sino al soddisfacimento di tale interesse. L’Utente può ottenere ulteriori informazioni in merito all’interesse legittimo perseguito dal Titolare nelle relative sezioni di questo documento o contattando il Titolare.</li>
                    </ul>
                    <p>Quando il trattamento è basato sul consenso dell’Utente, il Titolare può conservare i Dati Personali più a lungo sino a quando detto consenso non venga revocato. Inoltre, il Titolare potrebbe essere obbligato a conservare i Dati Personali per un periodo più lungo per adempiere ad un obbligo di legge o per ordine di un’autorità. <br>
                    <br> Al termine del periodo di conservazione i Dati Personali saranno cancellati. Pertanto, allo spirare di tale termine il diritto di accesso, cancellazione, rettificazione ed il diritto alla portabilità dei Dati non potranno più essere esercitati.
                    </p>
                </div>

                <div class="block">
                    <h6>Diritti dell’Utente sulla base del Regolamento Generale sulla Protezione dei Dati (GDPR)</h6>
                    <p>Gli Utenti possono esercitare determinati diritti con riferimento ai Dati trattati dal Titolare.</p>
                    <p>In particolare, nei limiti previsti dalla legge, l’Utente ha il diritto di:</p>
                    <ul>
                        <li>
                            <strong>revocare il consenso in ogni momento.</strong> L’Utente può revocare il consenso al trattamento dei propri Dati Personali precedentemente espresso.
                        </li>
                        <li>
                            <strong>opporsi al trattamento dei propri Dati.</strong> L’Utente può opporsi al trattamento dei propri Dati quando esso avviene in virtù di una base giuridica diversa dal consenso.
                        </li>
                        <li>
                            <strong>accedere ai propri Dati.</strong> L’Utente ha diritto ad ottenere informazioni sui Dati trattati dal Titolare, su determinati aspetti del trattamento ed a ricevere una copia dei Dati trattati.
                        </li>
                        <li>
                            <strong>verificare e chiedere la rettificazione.</strong> L’Utente può verificare la correttezza dei propri Dati e richiederne l’aggiornamento o la correzione.
                        </li>
                        <li>
                            <strong>ottenere la limitazione del trattamento.</strong> L’Utente può richiedere la limitazione del trattamento dei propri Dati. In tal caso il Titolare non tratterà i Dati per alcun altro scopo se non la loro conservazione.
                        </li>
                        <li>
                            <strong>ottenere la cancellazione o rimozione dei propri Dati Personali.</strong> L’Utente può richiedere la cancellazione dei propri Dati da parte del Titolare.
                        </li>
                        <li>
                            <strong>ricevere i propri Dati o farli trasferire ad altro titolare.</strong> L’Utente ha diritto di ricevere i propri Dati in formato strutturato, di uso comune e leggibile da dispositivo automatico e, ove tecnicamente fattibile, di ottenerne il trasferimento senza ostacoli ad un altro titolare.
                        </li>
                        <li>
                            <strong>proporre reclamo.</strong> L’Utente può proporre un reclamo all’autorità di controllo della protezione dei dati personali competente o agire in sede giudiziale.
                        </li>
                    </ul>

                    <p>Gli Utenti hanno diritto di ottenere informazioni in merito alla base giuridica per il trasferimento di Dati all'estero incluso verso qualsiasi organizzazione internazionale regolata dal diritto internazionale o costituita da due o più paesi, come ad esempio l’ONU, nonché in merito alle misure di sicurezza adottate dal Titolare per proteggere i loro Dati.</p>
                </div>

                <div class="block">
                    <h6>Dettagli sul diritto di opposizione</h6>
                    <p>
                    <strong>Quando i Dati Personali sono trattati nell’interesse pubblico, nell’esercizio di pubblici poteri di cui è investito il Titolare oppure per perseguire un interesse legittimo del Titolare, gli Utenti hanno diritto ad opporsi al trattamento per motivi connessi alla loro situazione particolare.</strong>
                    </p>
                    <p>
                    <strong>Si fa presente agli Utenti che, ove i loro Dati fossero trattati con finalità di marketing diretto, possono opporsi al trattamento in qualsiasi momento, gratuitamente e senza fornire alcuna motivazione. Qualora gli Utenti si oppongano al trattamento per finalità di marketing diretto, i Dati Personali non sono più oggetto di trattamento per tali finalità. Per scoprire se il Titolare tratti Dati con finalità di marketing diretto gli Utenti possono fare riferimento alle rispettive sezioni di questo documento. </strong>
                    </p>
                </div>

                <div class="block">
                    <h6>Come esercitare i diritti</h6>
                    <p>Per esercitare i propri diritti, gli Utenti possono indirizzare una richiesta ai recapiti del Titolare indicati in questo documento. La richiesta è gratuita e il Titolare risponderà nel più breve tempo possibile, in ogni caso entro un mese, fornendo all’Utente tutte le informazioni previste dalla legge. Eventuali rettifiche, cancellazioni o limitazioni del trattamento saranno comunicate dal Titolare a ciascuno dei destinatari, se esistenti, a cui sono stati trasmessi i Dati Personali, salvo che ciò si riveli impossibile o implichi uno sforzo sproporzionato. Il Titolare comunica all'Utente tali destinatari qualora egli lo richieda.</p>
                </div>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Ulteriori informazioni per gli utenti in Svizzera</h5>
                </div>

                <div class="block">
                    <p>Questa sezione si applica agli Utenti in Svizzera e, per tali Utenti, sostituisce qualsiasi altra informazione eventualmente divergente o in conflitto contenuta nell'informativa sulla privacy.</p>
                    <p>Ulteriori dettagli relativi alle categorie di Dati trattati, alle finalità del trattamento, alle categorie di destinatari dei dati personali, se presenti, al periodo di conservazione e ad altre informazioni sui Dati personali sono reperibili nella <strong>sezione intitolata "Informazioni dettagliate sul trattamento dei Dati personali" all'interno del presente documento</strong>. </p>
                </div>

                <div class="block">
                    <h6>I diritti degli utenti ai sensi della Legge federale sulla protezione dei dati personali</h6>
                    <p>Gli Utenti possono esercitare alcuni diritti relativi ai loro dati nei limiti della legge, tra cui i seguenti:</p>
                    <ul>
                        <li>diritto di accesso ai Dati personali;</li>
                        <li>il diritto di opporsi al trattamento dei propri Dati Personali (che consente anche agli Utenti di richiedere la limitazione del trattamento dei Dati personali, la cancellazione o la distruzione dei Dati personali, il divieto di divulgazione di Dati personali a terzi);</li>
                        <li>diritto di ricevere i propri Dati Personali e di trasferirli a un altro titolare del trattamento (portabilità dei dati);</li>
                        <li>diritto di chiedere la rettifica di Dati Personali errati.</li>
                    </ul>
                </div>

                <div class="block">
                    <h6>Come esercitare questi diritti</h6>
                    <p>Eventuali richieste di esercizio dei diritti dell'Utente possono essere indirizzate al Titolare attraverso i recapiti forniti in questo documento. Tali richieste sono gratuite e il Titolare risponderà nel più breve tempo possibile, fornendo agli Utenti le informazioni richieste dalla legge.</p>
                </div>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Ulteriori informazioni sul trattamento</h5>
                </div>

                <div class="block">
                    <h6>Difesa in giudizio</h6>
                    <p>I Dati Personali dell’Utente possono essere utilizzati da parte del Titolare in giudizio o nelle fasi preparatorie alla sua eventuale instaurazione per la difesa da abusi nell'utilizzo di questa Applicazione o dei Servizi connessi da parte dell’Utente.</p>
                    <p>L’Utente dichiara di essere consapevole che il Titolare potrebbe essere obbligato a rivelare i Dati per ordine delle autorità pubbliche.</p>
                </div>

                <div class="block">
                    <h6>Informative specifiche</h6>
                    <p>Su richiesta dell’Utente, in aggiunta alle informazioni contenute in questa privacy policy, questa Applicazione potrebbe fornire all'Utente delle informative aggiuntive e contestuali riguardanti Servizi specifici, o la raccolta ed il trattamento di Dati Personali.</p>
                </div>

                <div class="block">
                    <h6>Log di sistema e manutenzione</h6>
                    <p>Per necessità legate al funzionamento ed alla manutenzione, questa Applicazione e gli eventuali servizi terzi da essa utilizzati potrebbero raccogliere log di sistema, ossia file che registrano le interazioni e che possono contenere anche Dati Personali, quali l’indirizzo IP Utente.</p>
                </div>

                <div class="block">
                    <h6>Informazioni non contenute in questa policy</h6>
                    <p>Ulteriori informazioni in relazione al trattamento dei Dati Personali potranno essere richieste in qualsiasi momento al Titolare del Trattamento utilizzando gli estremi di contatto.</p>
                </div>

                <div class="block">
                    <h6>Modifiche a questa privacy policy</h6>
                    <p>Il Titolare del Trattamento si riserva il diritto di apportare modifiche alla presente privacy policy in qualunque momento notificandolo agli Utenti su questa pagina e, se possibile, su questa Applicazione nonché, qualora tecnicamente e legalmente fattibile, inviando una notifica agli Utenti attraverso uno degli estremi di contatto di cui è in possesso. Si prega dunque di consultare con frequenza questa pagina, facendo riferimento alla data di ultima modifica indicata in fondo.</p>
                    <p>Qualora le modifiche interessino trattamenti la cui base giuridica è il consenso, il Titolare provvederà a raccogliere nuovamente il consenso dell’Utente, se necessario.</p>
                </div>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <div class="block">
                    <h5>Definizioni e riferimenti legali</h5>
                </div>

                <div class="block">
                    <h6>Dati Personali (o Dati)</h6>
                    <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
                </div>

                <div class="block">
                    <h6>Dati di Utilizzo</h6>
                    <p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>
                </div>

                <div class="block">
                    <h6>Utente</h6>
                    <p>L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.</p>
                </div>

                <div class="block">
                    <h6>Interessato</h6>
                    <p>La persona fisica cui si riferiscono i Dati Personali.</p>
                </div>

                <div class="block">
                    <h6>Responsabile del Trattamento (o Responsabile)</h6>
                    <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
                </div>

                <div class="block">
                    <h6>Titolare del Trattamento (o Titolare)</h6>
                    <p>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.</p>
                </div>

                <div class="block">
                    <h6>Questa Applicazione</h6>
                    <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
                </div>

                <div class="block">
                    <h6>Servizio</h6>
                    <p>Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
                </div>

                <div class="block">
                    <h6>Unione Europea (o UE)</h6>
                    <p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.</p>
                </div>

                <div class="block">
                    <h6>Cookie</h6>
                    <p>I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.</p>
                </div>

                <div class="block">
                    <h6>Strumento di Tracciamento</h6>
                    <p>Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.</p>
                </div>

                <div class="block">
                    <h6>Riferimenti legali</h6>
                    <p>La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi.</p>
                    <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>
                </div>
            </bxs-layout>
        </section>

        <bxs-divider></bxs-divider>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <section>
            <bxs-layout>
                <bxs-collapse-item label="Definizioni e riferimenti legali">
                    <div class="pa-ui">
                        <div class="block">
                            <h6>Dati Personali (o Dati)</h6>
                            <p>Costituisce dato personale qualunque informazione che, direttamente o indirettamente, anche in collegamento con qualsiasi altra informazione, ivi compreso un numero di identificazione personale, renda identificata o identificabile una persona fisica.</p>
                        </div>

                        <div class="block">
                            <h6>Dati di Utilizzo</h6>
                            <p>Sono le informazioni raccolte automaticamente attraverso questa Applicazione (anche da applicazioni di parti terze integrate in questa Applicazione), tra cui: gli indirizzi IP o i nomi a dominio dei computer utilizzati dall’Utente che si connette con questa Applicazione, gli indirizzi in notazione URI (Uniform Resource Identifier), l’orario della richiesta, il metodo utilizzato nell’inoltrare la richiesta al server, la dimensione del file ottenuto in risposta, il codice numerico indicante lo stato della risposta dal server (buon fine, errore, ecc.) il paese di provenienza, le caratteristiche del browser e del sistema operativo utilizzati dal visitatore, le varie connotazioni temporali della visita (ad esempio il tempo di permanenza su ciascuna pagina) e i dettagli relativi all’itinerario seguito all’interno dell’Applicazione, con particolare riferimento alla sequenza delle pagine consultate, ai parametri relativi al sistema operativo e all’ambiente informatico dell’Utente.</p>
                        </div>

                        <div class="block">
                            <h6>Utente</h6>
                            <p>L'individuo che utilizza questa Applicazione che, salvo ove diversamente specificato, coincide con l'Interessato.</p>
                        </div>

                        <div class="block">
                            <h6>Interessato</h6>
                            <p>La persona fisica cui si riferiscono i Dati Personali.</p>
                        </div>

                        <div class="block">
                            <h6>Responsabile del Trattamento (o Responsabile)</h6>
                            <p>La persona fisica, giuridica, la pubblica amministrazione e qualsiasi altro ente che tratta dati personali per conto del Titolare, secondo quanto esposto nella presente privacy policy.</p>
                        </div>

                        <div class="block">
                            <h6>Titolare del Trattamento (o Titolare)</h6>
                            <p>La persona fisica o giuridica, l'autorità pubblica, il servizio o altro organismo che, singolarmente o insieme ad altri, determina le finalità e i mezzi del trattamento di dati personali e gli strumenti adottati, ivi comprese le misure di sicurezza relative al funzionamento ed alla fruizione di questa Applicazione. Il Titolare del Trattamento, salvo quanto diversamente specificato, è il titolare di questa Applicazione.</p>
                        </div>

                        <div class="block">
                            <h6>Questa Applicazione</h6>
                            <p>Lo strumento hardware o software mediante il quale sono raccolti e trattati i Dati Personali degli Utenti.</p>
                        </div>

                        <div class="block">
                            <h6>Servizio</h6>
                            <p>Il Servizio fornito da questa Applicazione così come definito nei relativi termini (se presenti) su questo sito/applicazione.</p>
                        </div>

                        <div class="block">
                            <h6>Unione Europea (o UE)</h6>
                            <p>Salvo ove diversamente specificato, ogni riferimento all’Unione Europea contenuto in questo documento si intende esteso a tutti gli attuali stati membri dell’Unione Europea e dello Spazio Economico Europeo.</p>
                        </div>

                        <div class="block">
                            <h6>Cookie</h6>
                            <p>I Cookie sono Strumenti di Tracciamento che consistono in piccole porzioni di dati conservate all'interno del browser dell'Utente.</p>
                        </div>

                        <div class="block">
                            <h6>Strumento di Tracciamento</h6>
                            <p>Per Strumento di Tracciamento s’intende qualsiasi tecnologia - es. Cookie, identificativi univoci, web beacons, script integrati, e-tag e fingerprinting - che consenta di tracciare gli Utenti, per esempio raccogliendo o salvando informazioni sul dispositivo dell’Utente.</p>
                        </div>

                        <div class="block">
                            <h6>Riferimenti legali</h6>
                            <p>La presente informativa privacy è redatta sulla base di molteplici ordinamenti legislativi.</p>
                            <p>Ove non diversamente specificato, questa informativa privacy riguarda esclusivamente questa Applicazione.</p>
                        </div>
                    </div>
                </bxs-collapse-item>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'privacy_policy'
}
</script>
