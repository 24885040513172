<template>
    <div class="about--view">
        <hero />

        <section>
            <bxs-layout>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'about'
}
</script>
