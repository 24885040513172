<template>
    <router-link
    v-if="to"
    :class="['btn-comp', {
        'btn-comp-light': light
    }]"
    :to="to"
    role="button">
        <span></span>

        <span>
            <slot />
        </span>

        <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.2396 6.7396C12.3087 6.80866 12.3475 6.90233 12.3475 7C12.3475 7.09767 12.3087 7.19134 12.2396 7.2604L9.11476 10.3852C9.0457 10.4543 8.95203 10.4931 8.85436 10.4931C8.75669 10.4931 8.66302 10.4543 8.59395 10.3852C8.52489 10.3162 8.48609 10.2225 8.48609 10.1248C8.48609 10.0272 8.52489 9.93349 8.59395 9.86443L11.0902 7.36821L0.521464 7.36873C0.473041 7.36873 0.425093 7.35919 0.380357 7.34066C0.33562 7.32213 0.294972 7.29497 0.260732 7.26073C0.226492 7.22649 0.199332 7.18584 0.180801 7.14111C0.162271 7.09637 0.152733 7.04842 0.152733 7C0.152733 6.95158 0.162271 6.90363 0.180801 6.85889C0.199332 6.81416 0.226492 6.77351 0.260732 6.73927C0.294972 6.70503 0.33562 6.67787 0.380357 6.65934C0.425093 6.64081 0.473041 6.63127 0.521464 6.63127L11.0902 6.63179L8.59395 4.13557C8.52489 4.0665 8.48609 3.97283 8.48609 3.87516C8.48609 3.77749 8.52489 3.68382 8.59395 3.61476C8.66302 3.5457 8.75669 3.5069 8.85436 3.5069C8.95203 3.5069 9.0457 3.5457 9.11476 3.61476L12.2396 6.7396Z"></path>
        </svg>
    </router-link>

    <a
    v-else
    role="button"
    :class="['btn-comp', {
        'btn-comp-light': light
    }]"
    :to="to">
        <span></span>

        <span>
            <slot />
        </span>

        <svg
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
            <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.2396 6.7396C12.3087 6.80866 12.3475 6.90233 12.3475 7C12.3475 7.09767 12.3087 7.19134 12.2396 7.2604L9.11476 10.3852C9.0457 10.4543 8.95203 10.4931 8.85436 10.4931C8.75669 10.4931 8.66302 10.4543 8.59395 10.3852C8.52489 10.3162 8.48609 10.2225 8.48609 10.1248C8.48609 10.0272 8.52489 9.93349 8.59395 9.86443L11.0902 7.36821L0.521464 7.36873C0.473041 7.36873 0.425093 7.35919 0.380357 7.34066C0.33562 7.32213 0.294972 7.29497 0.260732 7.26073C0.226492 7.22649 0.199332 7.18584 0.180801 7.14111C0.162271 7.09637 0.152733 7.04842 0.152733 7C0.152733 6.95158 0.162271 6.90363 0.180801 6.85889C0.199332 6.81416 0.226492 6.77351 0.260732 6.73927C0.294972 6.70503 0.33562 6.67787 0.380357 6.65934C0.425093 6.64081 0.473041 6.63127 0.521464 6.63127L11.0902 6.63179L8.59395 4.13557C8.52489 4.0665 8.48609 3.97283 8.48609 3.87516C8.48609 3.77749 8.52489 3.68382 8.59395 3.61476C8.66302 3.5457 8.75669 3.5069 8.85436 3.5069C8.95203 3.5069 9.0457 3.5457 9.11476 3.61476L12.2396 6.7396Z"></path>
        </svg>
    </a>
</template>

<script>
export default {
    name: 'btn_comp',
    props: {
        to: String,
        light: Boolean
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';

.btn-comp {
    height: 40px;
    line-height: 40px;
    display: inline-block;
    position: relative;
    padding: 0 25px;
    color: var(--color-primary);
    font-size: 14px;
    font-family: var(--typo-family-action);
    text-transform: uppercase;
    z-index: 1;

    &::after,
    &::before {
        display: none;
    }

    &:hover {
        > span {
            &:first-child {
                width: 100%;
                border-radius: 40px;
                border-color: map-get($colors, 'primary');
            }
        }

        > svg {
            margin-left: 25px;
        }
    }

    &.btn-comp-light {
        color: var(--color-white) !important;

        > span {
            &:first-child {
                border-color: var(--color-white);
            }
        }

        > svg {
            fill: var(--color-white);
        }
    }

    > svg {
        float: right;
        margin-top: 12px;
        margin-left: 15px;
        fill: var(--color-primary);
        z-index: 1;
        transition: all .25s ease-in-out;
    }

    > span {
        &:first-child {
            width: 40px;
            height: 40px;
            position: absolute;
            left: 0;
            top: 0;
            border: 1px solid rgba(map-get($colors, 'primary'), 0.5);
            border-radius: 40px;
            transition: all .25s ease-in-out;
            z-index: -1;
        }

        &:last-child {
            z-index: 1;
        }
    }

    > svg {
        float: right;
        margin-top: 12px;
        margin-left: 15px;
        fill: var(--color-primary);
        z-index: 1;
        transition: all .25s ease-in-out;
    }
}
</style>