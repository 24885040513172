<template>
    <div class="bxs-divider">
    </div>
</template>

<script>
export default {
    name: 'divider'
}
</script>

<style lang="scss">
    hr,
    .bxs-divider {
        display: block;
        flex: 1 1 0px;
        max-width: 100%;
        height: 0;
        max-height: 0;
        border: solid;
        border-width: thin 0 0;
        transition: inherit;
        border-color: var(--divider-color);
        overflow: visible;

        &.bxs-divider-vertical {
            align-self: stretch;
            border: solid;
            border-width: 0 thin 0 0;
            display: inline-flex;
            height: inherit;
            min-height: 100%;
            max-height: 100%;
            max-width: 0;
            width: 0;
            vertical-align: text-bottom;
            margin: 0 -1px;
        }
    }
</style>