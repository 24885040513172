<template>
    <header :class="['bxs-navframe', {
        'bxs-navframe-actived': actived,
        'bxs-navframe-hidden': hidden
    }]">
        <!-- ------------------------------------------------------------------------------------------------------------------------------
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <nav v-if="!$store.state.is_mobile">
            <div>
                <ul>
                    <li>
                        <bxs-logo
                        src="/img/logo-color.png"
                        class="mr-24" />
                    </li>
                </ul>
            </div>

            <div class="col-50">
                <ul class="flex nowrap align-center">
                    <!-- <li class="mr-ui">
                        <router-link to="/">Home</router-link>
                    </li> -->

                    <li class="mr-ui">
                        <router-link
                        v-if="$route.name !== 'index'"
                        to="/">Chi siamo</router-link>

                        <a
                        v-else
                        @click="scrollTo('section-about')">Chi siamo</a>
                    </li>

                    <!-- <li class="mr-ui">
                        <router-link to="/chi-siamo">Team</router-link>
                    </li> -->

                    <li class="mr-ui">
                        <router-link
                        v-if="$route.name !== 'index'"
                        to="/">Servizi di trasporto</router-link>

                        <a
                        v-else
                        @click="scrollTo('section-services')">Servizi di trasporto</a>
                    </li>

                    <!-- <li class="mr-ui">
                        <router-link to="/supporto-clienti">Assitenza clienti</router-link>
                    </li> -->

                    <!-- <li class="mr-ui">
                        <router-link to="/sostenibilita">Sostenibilità</router-link>
                    </li> -->

                    <!-- <li class="mr-ui">
                        <router-link to="/lavora-con-noi">Lavora con noi</router-link>
                    </li> -->

                    <!-- <li class="mr-ui">
                        <router-link to="/news">News</router-link>
                    </li> -->

                    <li>
                        <router-link
                        v-if="$route.name !== 'index'"
                        to="/">Contatti</router-link>

                        <a
                        v-else
                        @click="scrollTo('footer')">Contatti</a>
                    </li>
                </ul>
            </div>

            <div class="justify-end">
                <ul class="flex nowrap align-center justify-end">
                    <li class="mr-ui">
                        <a href="mailto:info@fusco.ch">info@fusco.ch</a>
                    </li>

                    <li>
                        <a href="tel:+41919402335">(+41) 91 940 23 35</a>
                    </li>

                    <!-- <li class="mr-ui">
                        <a
                        href="https://facebook.com"
                        target="_blank">
                            <bxs-icon name="fb"></bxs-icon>
                        </a>
                    </li> -->

                    <!-- <li>
                        <a
                        href="https://instagram.com"
                        target="_blank">
                            <bxs-icon name="ig"></bxs-icon>
                        </a>
                    </li> -->
                </ul>
            </div>

            <!-- <div class="bxs-navframe--line"></div> -->
        </nav>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
            nav mobile
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <nav
        v-else
        class="flex nowrap">
            <div class="flex-1">
                <ul>
                    <li>
                        <bxs-logo
                        src="/img/logo-color.png"
                        class="mr-24" />
                    </li>
                </ul>
            </div>

            <div class="justify-end">
                <ul class="flex nowrap align-center justify-end">
                    <li>
                        <bxs-icon
                        name="list"
                        width="20"
                        height="20"
                        @click="on = true" />
                    </li>
                </ul>
            </div>
        </nav>

        <!-- ------------------------------------------------------------------------------------------------------------------------------
            menu
        ------------------------------------------------------------------------------------------------------------------------------ -->
        <bxs-drawer
        v-if="$store.state.is_mobile"
        v-model="on">
            <div class="pt-ui">
                <ul class="h5 mb-ui">
                    <!-- <li class="mb-2">
                        <router-link to="/">Home</router-link>
                    </li> -->

                    <li class="mb-1">
                        <a @click="scrollTo('section-about')">Chi siamo</a>
                    </li>

                    <!-- <li class="mb-2">
                        <router-link to="/chi-siamo">Team</router-link>
                    </li> -->

                    <li class="mb-1">
                        <a @click="scrollTo('section-services')">Servizi di trasporto</a>
                    </li>

                    <!-- <li class="mr-ui">
                        <router-link to="/supporto-clienti">Assitenza clienti</router-link>
                    </li> -->

                    <!-- <li class="mr-ui">
                        <router-link to="/sostenibilita">Sostenibilità</router-link>
                    </li> -->

                    <!-- <li class="mr-ui">
                        <router-link to="/lavora-con-noi">Lavora con noi</router-link>
                    </li> -->

                    <!-- <li class="mr-ui">
                        <router-link to="/news">News</router-link>
                    </li> -->

                    <li class="mb-1">
                        <a @click="scrollTo('footer')">Contatti</a>
                    </li>

                    <li class="mb-1">
                        <router-link to="/privacy-policy">Privacy Policy</router-link>
                    </li>

                    <li>
                        <router-link to="/cookie-policy">Cookie Policy</router-link>
                    </li>
                </ul>

                <div class="mb-ui">
                    <!-- <div>
                        <div class="mb-ui">
                            <p class="text-tertiary text-uppercase mb-4">Email logistica</p>
                            <a href="mailto:info@fusco.ch" class="mb-0">info@fusco.ch</a>
                        </div>

                        <div class="mb-ui">
                            <p class="text-tertiary text-uppercase mb-4">Email amministrazione</p>
                            <a href="mailto:admin@fusco.ch" class="mb-0">admin@fusco.ch</a>
                        </div>

                        <div class="mb-ui">
                            <p class="text-tertiary text-uppercase mb-4">Email sales</p>
                            <a href="mailto:sales@fusco.ch" class="mb-0">sales@fusco.ch</a>
                        </div>
                    </div> -->

                    <div>
                        <a
                        href="tel:+41919402335"
                        class="text-mute link-mute">
                            <bxs-icon
                            name="phone-fill"
                            width="20"
                            height="20"
                            class="mr-6"></bxs-icon>
                        </a>

                        <a
                        href="https://maps.app.goo.gl/yHFByX5xAUfEaTY56"
                        target="_blank"
                        class="text-mute link-mute">
                            <bxs-icon
                            name="geo-fill"
                            width="20"
                            height="20"
                            class="mr-6"></bxs-icon>
                        </a>

                        <a
                        href="https://www.instagram.com/fusco.ch/"
                        target="_blank"
                        class="text-mute link-mute">
                            <bxs-icon
                            name="ig"
                            width="20"
                            height="20"
                            class="mr-6"></bxs-icon>
                        </a>

                        <a
                        href="https://www.facebook.com/FUSCOEXPRESS/"
                        target="_blank"
                        class="text-mute link-mute">
                            <bxs-icon
                            name="fb"
                            width="20"
                            height="20"
                            class="mr-6"></bxs-icon>
                        </a>

                        <a
                        href="https://www.linkedin.com/company/fusco-express-sagl/about/"
                        target="_blank"
                        class="text-mute link-mute">
                            <bxs-icon
                            width="20"
                            height="20"
                            name="li"></bxs-icon>
                        </a>
                    </div>
                </div>

                <div>
                    <small>© {{ new Date().getFullYear() }} Fusco Express Sagl. All rights reserved. CHE-113.025.984</small>
                </div>
            </div>
        </bxs-drawer>
    </header>
</template>

<script>
export default {
    name: 'bxs_navframe',
    props: {
        actived: Boolean,
        hidden: Boolean
    },
    data () {
        return {
            on: false
        }
    },
    watch: {
        $route () {
            this.on = false
        }
    },
    mounted () {
        console.log('navframe', this)
    },
    methods: {
        scrollTo (v) {
            this.$root.scroll.scrollTo('#' + v)
            this.on = !this.on
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mq.scss';

.bxs-navframe {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: var(--navbar-min-height-mobile);
    color: var(--navbar-color);
    font-size: var(--navbar-font-size);
    z-index: var(--layer-navbar);
    background-color: var(--navbar-background-color);
    transition: transform 0.4s ease-out, background-color 0.25s ease-out;

    @include mq(ds) {
        height: var(--navbar-min-height);
    }

    &.bxs-navframe-actived {
        background-color: map-get($colors, 'background');
        // backdrop-filter: blur(20px);

        nav {
            border-bottom: 1px solid var(--navbar-border-color);
        }
    }

    &.bxs-navframe-hidden {
        transform: translateY(-102%);
    }

    ul {
        height: 100%;

        li {
            height: 100%;
            display: flex;
            align-items: center;

            a  {
                height: 100%;
                display: flex;
                align-items: center;

                &::after {
                    height: 2px;
                    bottom: -2px;
                }
            }
        }
    }

    nav {
        position: relative;
        width: 100%;
        height: 100%;
        max-width: var(--navbar-max-width);
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        border-radius: 4px;

        @include mq(ds) {
            border-bottom: 1px solid var(--navbar-border-color);
        }

        .col-50 {
            width: 50%;
        }

        > div {
            position: relative;
            height: 100%;
            padding: 0 var(--grid-gutter);

            &:first-child,
            &:last-child {
                width: 25%;
            }

            &:nth-child(2) {
                @include mq(ds) {
                    border-left: 1px solid rgba(#000, 0.25);
                    border-right: 1px solid rgba(#000, 0.25);
                }
            }
        }
    }

    &--line {
        position: absolute;
        left: var(--navbar-padding);
        bottom: 0;
        width: calc(100% - (var(--navbar-padding) * 2));
        height: 1px;
        background-color: var(--color-typo);
    }
}
</style>