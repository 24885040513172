import { getCSSVar } from '../assets/libs/utils/dom'

export default {
    props: {
        ratio: {
            type: [String, Number, Boolean],
            required: false,
            default: false
        },
        'ratio-breakpoints': {
            type: Object,
            required: false,
            default: () => {}
        },
        'ratio-debug': {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        ratio_is_setted () {
            return !!this.ratio_local
        },
        ratio_classes() {
            return {
                'bxs-r': !!this.ratio_is_setted
            }
        },
        ratio_styles() {
            const v = {}

            if (this.ratio_local) {
                v['--ratio-w'] = this.ratio_local.split('-')[0]
                v['--ratio-h'] = this.ratio_local.split('-')[1]
            }

            return v
        }
    },
    data() {
        return {
            ratio_local_current_break: String(getCSSVar('--break-value').split('-')[0]).trim(),
            ratio_local_matches: {},
            ratio_local_app_breakpoints: {
                ms: parseInt(getCSSVar('--break-ms').replace('px', '')),
                ml: parseInt(getCSSVar('--break-ml').replace('px', '')),
                mm: parseInt(getCSSVar('--break-mm').replace('px', '')),
                ds: parseInt(getCSSVar('--break-ds').replace('px', '')),
                dm: parseInt(getCSSVar('--break-dm').replace('px', '')),
                dl: parseInt(getCSSVar('--break-dl').replace('px', ''))
            },
            ratio_local: this.ratio
        }
    },
    mounted() {
        this.$nextTick(this.ratioPlay)
    },
    methods: {
        ratioPlay () {
            if (this.ratioBreakpoints && Object.keys(this.ratioBreakpoints).length > 0) {
                if ('mm' in this.ratioBreakpoints) {
                    this.ratio_local_matches.mm = window.matchMedia('screen and (max-width: 575px)')
                    this.ratio_local_matches.mm.addListener(e => this.ratioHandleMatchMedia(e, 'mm'))
                    this.ratioHandleMatchMedia(this.ratio_local_matches.mm, 'mm')
                }

                if ('ml' in this.ratioBreakpoints) {
                    this.ratio_local_matches.ml = window.matchMedia('screen and (min-width: 576px) and (max-width: 991px)')
                    this.ratio_local_matches.ml.addListener(e => this.ratioHandleMatchMedia(e, 'ml'))
                    this.ratioHandleMatchMedia(this.ratio_local_matches.ml, 'ml')
                }

                if ('ds' in this.ratioBreakpoints) {
                    this.ratio_local_matches.ds = window.matchMedia('screen and (min-width: 992px)')
                    this.ratio_local_matches.ds.addListener(e => this.ratioHandleMatchMedia(e, 'ds'))
                    this.ratioHandleMatchMedia(this.ratio_local_matches.ds, 'ds')
                }
            }
        },
        ratioHandleMatchMedia (e, v) {
            if (e && e.matches && this.ratioBreakpoints[v]) {
                this.ratio_local = this.ratioBreakpoints[v]
            }

            // if (this.ratioDebug) {
            //     console.log(
            //         'ratioHandleMatchMedia',
            //         v, e.matches,
            //         this.ratio_local
            //     )
            // }

            // console.log('ratio ratioResize()', this.ratioBreakpoints, this.ratio_local_matches, this.ratio_local)
        }
    },
    beforeUnmount() {
        for (const k in this.ratioBreakpoints) {
            this.ratio_local_matches[k].removeListener(this.ratioHandleMatchMedia)
        }
    }
}