<template>
    <div :class="['select-field', {
        'select-field-actived': on,
        'select-field-disabled': disabled,
        'select-field-block': block,
        'select-field-outlined': outlined
    }]"
    :id="local_id"
    :data-select="local_id">
        <!-- ------------------------------------------------------------
        ------------------------------------------------------------ -->
        <div
        ref="activator"
        :class="['select-field--activator', {
            'select-field--activator-selected': is_valorized
        }]"
        @click="on = !on">
            <div class="flex nowrap align-center">
                <span class="select-field--activator--label">
                    <!-- v-if="!is_valorized" -->
                    <span>
                        <span v-if="!showSelectedItemValue">{{ label }}</span>
                        <span v-else>{{ getItemValue(cacheValue[0], itemText) || label }}</span>
                    </span>

                    <span
                    v-if="multiple && is_valorized"
                    class="select-field--activator--label--counts ml-2">{{ cacheValue.length }}</span>

                    <!-- <span>
                        <span v-if="!multiple">{{ getItemValue(cacheValue[0], itemText) }}</span>
                        <span v-else>
                            <span>{{ label }}</span>
                            <span class="select-field--activator--label--counts ml-2">{{ cacheValue.length }}</span>
                        </span>
                    </span> -->
                </span>

                <bxs-icon
                v-if="clearable && !multiple && is_valorized"
                name="close"
                class="ml-2"
                @click="clear" />
            </div>

            <bxs-icon
            class="select-field--activator--icon"
            :name="!on ? 'chevron-down' : 'chevron-top'"
            style="margin-top: 1px;" />
        </div>

        <!-- ------------------------------------------------------------
        ------------------------------------------------------------ -->
        <Teleport to="body">
            <!-- ------------------------------------------------------------
            ------------------------------------------------------------ -->
            <div
            v-if="on"
            :data-select="local_id"
            class="select-field--divider"
            :style="{
                width: divider_width + 'px',
                top: divider_top + 'px',
                left: divider_left + 'px'
            }"></div>

            <!-- ------------------------------------------------------------
            ------------------------------------------------------------ -->
            <div
            :data-select="local_id"
            v-show="on"
            :class="['select-field--content', {
                'select-field--content-actived': on
            }]"
            :style="{
                width: (content_width + contentMinWidth) + 'px',
                top: content_top + 'px',
                left: content_left + 'px'
            }">
                <!-- ------------------------------------------------------------
                ------------------------------------------------------------ -->
                <ul class="select-field--content--scroller bxs-scrollable">
                    <li
                    v-for="(item, i) in cacheItems"
                    :key="item.id || i"
                    :data-select="local_id"
                    :class="['select-field--content--scroller--item', {
                        disabled: isDisabledItem(item),
                        selected: isSelectedItem(item)
                    }]"
                    @click="select(item)">
                        <slot
                        name="prepend"
                        :item="item" />

                        <slot
                        name="label"
                        :item="item" />

                        <span v-if="!$slots.label">{{ getItemValue(item, itemText) }}</span>

                        <slot
                        name="append"
                        :item="item" />

                        <bxs-icon
                        v-if="!$slots.append && isSelectedItem(item)"
                        name="check" />
                    </li>
                </ul>

                <!-- ------------------------------------------------------------
                ------------------------------------------------------------ -->
                <div
                v-if="$slots.actions"
                class="select-field--content--actions">
                    <slot
                    name="actions"
                    :is_open="on"
                    :is_valorized="is_valorized"
                    :clear="clear"
                    :close="close"
                    :value="computedModelValue" />
                </div>
            </div>
        </Teleport>
    </div>
</template>

<script>
import { randomAlpha } from '@/assets/libs/utils/string'
import dotObj from 'dot-object'

export default {
    name: 'bxs_select_field',
    props: {
        modelValue: {
            type: [String, Array, Object],
            required: false,
            default: null
        },
        id: {
            type: String,
            required: false,
            default: null
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        //
        'item-text': {
            type: String,
            required: false,
            default: 'name'
        },
        'item-value': {
            type: String,
            required: false,
            default: 'value'
        },
        'item-disabled': {
            type: [String, Function],
            required: false,
            default: 'disabled'
        },
        items: {
            type: Array,
            required: false,
            default: () => []
        },
        'auto-update': {
            type: Boolean,
            required: false,
            default: true
        },
        'return-object': {
            type: Boolean,
            required: false,
            default: false
        },
        'return-object-on-change': {
            type: Boolean,
            required: false,
            default: false
        },
        'show-selected-item-value': {
            type: Boolean,
            required: false,
            default: false
        },
        multiple: {
            type: Boolean,
            required: false,
            default: false
        },
        //
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        autofocus: {
            type: Boolean,
            required: false,
            default: false
        },
        block: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        'close-on-click-content': {
            type: Boolean,
            required: false,
            default: false
        },
        'close-on-click-outside': {
            type: Boolean,
            required: false,
            default: true
        },
        'content-min-width': {
            type: Number,
            required: false,
            default: 0
        }
    },
    emits: [
        'update:model-value',
        'change',
        'open',
        'close'
    ],
    data () {
        return {
            on: this.autofocus,
            local_id: this.id,
            //
            content_top: 0,
            content_left: 0,
            content_width: 0,
            divider_width: 0,
            divider_top: 0,
            divider_left: 0,
            el_width: 0,
            //
            cacheItems: this.items,
            cacheValue: [],
            dotObj: dotObj
        }
    },
    computed: {
        computedModelValue () {
            let val = this.multiple ? this.cacheValue : this.cacheValue[0]

            if (!this.returnObject) {
                if (this.multiple) {
                    const _v = []

                    if (this.cacheValue.length > 0) {
                        this.cacheValue.forEach(i => _v.push(this.getItemValue(i)))
                    }

                    val = _v
                } else {
                    val = this.getItemValue(this.cacheValue[0])
                }
            }

            return val
        },
        computedModelValueObject () {
            return this.multiple ? this.cacheValue : this.cacheValue[0]
        },
        selected_values_refs () {
            if (!this.is_valorized) return []
            return this.cacheValue.map(i => this.getItemValue(i))
        },
        is_valorized () {
            return this.cacheValue && this.cacheValue.length > 0
        }
    },
    created () {
        if (!this.id) {
            this.local_id = randomAlpha(5)
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        modelValue (newVal) {
            if (this.debug) console.log('select_field watch modelValue')
            this.setValue()
        },
        items (newVal) {
            this.cacheItems = newVal
        },
        on (newVal, oldVal) {
            if (this.debug) console.log('select_field watch on')

            if (newVal) this.open()
            else this.close()

            this.resize()
        }
    },
    methods: {
        start () {
            if (this.debug) console.log('select_field start()')

            this.setValue()

            this.resize()
            window.addEventListener('resize', this.resize)
            document.addEventListener('click', this.handleClickOutside)
        },
        stop () {
            if (this.debug) console.log('select_field stop()')

            window.removeEventListener('resize', this.resize)
            document.removeEventListener('click', this.handleClickOutside)
        },
        // --------------------------------------------------------------------------------------------------------
        setValue () {
            if (this.debug) console.log('select_field setValue()', this.modelValue)

            const isObject = (v) => typeof v === 'object' && !Array.isArray(v)

            // '', [], {}
            if (this.modelValue) {
                const values = []

                if (isObject(this.modelValue) && Object.keys(this.modelValue).length > 0) {
                    if (this.debug) console.log('val obj')

                    const _i = this.cacheItems.find(i => this.dotObj.pick(this.itemValue, i) === this.getItemValue(this.modelValue))
                    if (_i) values.push(_i)
                } else if (Array.isArray(this.modelValue) && this.modelValue.length > 0) {
                    if (this.debug) console.log('val arr')

                    this.cacheItems.forEach((i) => {
                        if (this.modelValue.includes(this.getItemValue(i))) {
                            values.push(i)
                        }
                    })
                } else {
                    const _i = this.cacheItems.find(i => this.dotObj.pick(this.itemValue, i) === this.modelValue)
                    if (_i) values.push(_i)
                }

                this.cacheValue = values
            } else {
                this.cacheValue = []
            }
        },
        // getModelValue () {
        //     if (this.debug) console.log('select_field getModelValue()')

        //     let val = this.multiple ? this.cacheValue : this.cacheValue[0]

        //     if (!this.returnObject) {
        //         if (this.multiple) {
        //             const _v = []

        //             if (this.cacheValue.length > 0) {
        //                 this.cacheValue.forEach(i => _v.push(this.getItemValue(i)))
        //             }

        //             val = _v
        //         } else {
        //             val = this.getItemValue(this.cacheValue[0])
        //         }
        //     }

        //     return val
        // },
        updateModelValue () {
            if (this.debug) console.log('select_field updateModelValue()')

            this.$emit('update:model-value', this.computedModelValue)

            this.$nextTick(() => {
                this.resize()
            })

            return this.computedModelValue
        },
        emitChange () {
            if (this.debug) console.log('select_field emitChange()')

            let v = this.computedModelValue

            if (!this.returnObject && this.returnObjectOnChange) {
                v = this.computedModelValueObject
            }

            this.$emit('change', v)
        },
        // --------------------------------------------------------------------------------------------------------
        handleClickOutside (evt) {
            if (this.debug) console.log('select_field handleClickOutside()')
            const clicked_el = evt.target
            const clicked_el_ref = clicked_el.dataset.select

            if (this.debug) {
                console.log(' current id', this.local_id)
                console.log(' clicked id', clicked_el_ref)
            }

            /**
             * chiude sè:
             * elemento cliccato !== questo elemento            [v]
             * questo elemento non include elemento cliccato    [v]
             * lista item cliccato non esiste in questo element []
             */
            if (
                this.closeOnClickOutside &&
                this.on &&
                clicked_el !== this.$el &&
                !this.$el.contains(clicked_el) &&
                clicked_el_ref !== this.local_id
            ) {
                if (this.debug) console.log('click outside')
                this.close()
            }
        },
        resize () {
            if (this.debug) console.log('select_field resize()')

            this.$nextTick(() => {
                const rect = this.$el.getBoundingClientRect()

                this.el_width = rect.width

                this.content_width = rect.width
                this.content_top = rect.top + rect.height
                this.content_left = rect.left

                this.divider_width = this.el_width - 4
                this.divider_top = (rect.top + rect.height) - 2
                this.divider_left = rect.left + 2
            })
        },
        // --------------------------------------------------------------------------------------------------------
        isDisabledItem (item) {
            return this.getItemValue(item, this.itemDisabled) && this.getItemValue(item, this.itemDisabled) === true
        },
        isSelectedItem (item) {
            return this.selected_values_refs.includes(this.getItemValue(item))
        },
        getItemValue (item, key) {
            return this.dotObj.pick(key || this.itemValue, item)
        },
        // --------------------------------------------------------------------------------------------------------
        select (item) {
            if (this.debug) console.log('select_field select()')

            if (this.isSelectedItem(item)) {
                const i = this.cacheValue.findIndex(i => this.getItemValue(i) === this.getItemValue(item))
                this.cacheValue.splice(i, 1)
            } else {
                if (this.multiple) this.cacheValue.push(item)
                else this.cacheValue = [item]
            }

            this.updateModelValue()
            this.emitChange()

            this.on = !this.closeOnClickContent
        },
        clear () {
            if (this.debug) console.log('select_field clear()')

            this.cacheValue = []
            this.updateModelValue()
            this.emitChange()
        },
        open () {
            if (this.debug) console.log('select_field open()')

            this.on = true
            this.$emit('open', this.computedModelValue)
        },
        close () {
            if (this.debug) console.log('select_field close()')

            this.on = false
            this.$emit('close', this.autoUpdate ? this.updateModelValue() : this.computedModelValue)
        }
    },
    beforeUnmount () {
        this.stop()
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/conf.scss';
@import '@/assets/styles/mq.scss';

$color: var(--color-typo);
$background-color: var(--color-white);
$divider-color: rgba(#000, 0.2);
$border-radius: var(--radius);
$font-weight-actived: 600;
$border-actived: inset 0 0 0px 1px #000;
$border-width-actived: 2px;

.select-field {
    position: relative;
    display: inline-block;
    background-color: $background-color;
    cursor: pointer;
    user-select: none;
    border-radius: $border-radius;
    font-size: 1rem;

    &.select-field-block {
        max-width: 100%;
    }

    &.select-field-outlined {
        .select-field--activator {
            border: var(--thickness) solid $color;
        }
    }

    &.select-field-actived {
        .select-field--activator {
            box-shadow: $border-actived;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
        }
    }

    &:hover {
        .select-field--activator {
            box-shadow: $border-actived;
        }
    }

    // ------------------------------------------------------------
    &--activator {
        min-height: var(--min-height-ui);
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        background-color: $background-color;
        border-radius: $border-radius;

        &.select-field--activator-selected {
            box-shadow: $border-actived;
            .select-field--activator--label {
                font-weight: $font-weight-actived;
            }
        }

        &--label {
            font-weight: 400;
            font-size: 1rem;
            pointer-events: none;

            &--counts {
                background-color: var(--color-typo);
                color: var(--color-background);
                padding: 0 0.25rem;
                border-radius: var(--radius);
            }
        }

        &--icon {
            pointer-events: none;
            margin-left: 1.5rem;
        }
    }

    // ------------------------------------------------------------

    &--divider {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: $background-color;
        pointer-events: none;
        z-index: 2;
    }

    // ------------------------------------------------------------

    &--content {
        position: absolute;
        top: 0;
        left: 0;

        display: flex;
        flex-flow: column nowrap;
        align-items: space-between;

        width: 100%;
        // max-width: 320px;
        max-height: 400px;

        background-color: $background-color;
        border: $border-width-actived solid $color;
        border-radius: $border-radius;
        border-top-left-radius: 0;

        z-index: 1;
        pointer-events: auto;

        > ul {
            position: relative;
            display: block;
            flex: 1;

            li {
                display: flex;
                flex-flow: row nowrap;
                align-items: center;
                justify-content: space-between;

                width: 100%;
                min-height: var(--min-height-ui);
                padding: 0 1rem;

                font-size: 1em;
                font-weight: 400;
                border-bottom: 1px solid $divider-color;

                cursor: pointer;

                &:last-child {
                    margin-bottom: 0;
                    border-bottom-color: transparent;
                }

                @media (hover: hover) and (pointer: fine) {
                    &:hover {
                        background-image: linear-gradient(rgb(0 0 0/10%) 0 0);
                    }
                }

                &.selected {
                    font-weight: $font-weight-actived;
                }

                &.disabled {
                    background-color: transparent !important;
                    color: var(--color-disabled) !important;
                }
            }
        }

        &--actions {
            position: relative;
            display: flex;
            flex-flow: row nowrap;
            min-height: var(--min-height-ui);
            border-top: 1px solid $divider-color;
        }
    }
}
</style>