import components from './components'

// projects
import Grid from '@/components/project/Grid.vue'
import Hero from '@/components/project/Hero.vue'
import Btn from '@/components/project/Btn.vue'
import Arrow from '@/components/project/Arrow.vue'

// libs
import Toast from 'vue-toastification'
import stickybits from 'stickybits'

// import { DatePicker } from 'v-calendar'
// import 'v-calendar/dist/style.css'
import 'vue-toastification/dist/index.css'

const convert = (v) => {
    const text = v
    const result = text.replace(/([A-Z])/g, ' $1')
    const f = result.charAt(0).toUpperCase() + result.slice(1)
    return f.replaceAll(' ', '-').toLowerCase()
}

export default function createComp(app) {
    for (const k in components) {
        console.log(convert('bxs' + k), components[k])
        app.component(
            convert('bxs' + k),
            components[k]
        )
    }

    app.component('grid', Grid)
    app.component('hero', Hero)
    app.component('btn', Btn)
    app.component('arrow', Arrow)

    console.log('\n')

    // app.component('bxs-date-picker', DatePicker)

    app.use(Toast, {
        position: 'top-center',
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        newestOnTop: false,
        transition: 'Vue-Toastification__fade',
        bodyClassName: ['bxs-notify'],
        maxToasts: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        draggable: false
    })

    app.config.globalProperties.$sticky = stickybits
}