<template>
  <div
  ref="app"
  :class="['bxs-app', {
    'bxs-app-custom-scroll': is_custom_scroll,
    'bxs-loading': is_in_transition || is_loading || !is_preloaded,
    'bxs-disabled': is_in_transition
  }]">
    <!-- ------------------------------------------------------------------------------------------------------------
      preloader
    ------------------------------------------------------------------------------------------------------------ -->
    <transition
    @enter="enterPreloader"
    mode="out-in"
    appear>
      <bxs-preloader
      v-if="!is_preloaded"
      ref="preloader"
      :resources="resources"
      class="bxs-app--preloader">
        <template #default="{ progress_scale }">
          <div v-if="has_preloader">
            <!-- <bxs-logo max-width="200px" /> -->
            <h1>Loading</h1>

          <div
          ref="progress"
          class="bxs-preloader--progress">
            <div
            class="bxs-preloader--progress--line"
            :style="{
              transform: `scaleX(${progress_scale})`
            }"></div>
          </div>
          </div>
        </template>
      </bxs-preloader>
    </transition>

    <!-- ------------------------------------------------------------------------------------------------------------
      wrapper
    ------------------------------------------------------------------------------------------------------------ -->
    <div
    v-if="is_preloaded"
    ref="wrapper"
    id="wrapper"
    class="bxs-app--wrapper">
      <!-- ------------------------------------------------------------------------------------------------------------
        navframe
      ------------------------------------------------------------------------------------------------------------ -->
      <bxs-navframe
      ref="navframe"
      :actived="navframe.is_actived"
      :hidden="navframe.is_hidden"
      class="bxs-app--navframe" />

      <!-- ------------------------------------------------------------------------------------------------------------
        view
      ------------------------------------------------------------------------------------------------------------ -->
      <main
      ref="main"
      class="bxs-app--main"
      data-scroll-container>
        <router-view
        ref="view"
        v-slot="{ Component }">
          <transition
          @enter="enterPage"
          @leave="leavePage"
          mode="out-in"
          appear>
            <component
            :is="Component"
            :key="$route.name"
            ref="page"
            class="bxs-app--page"
            @click:cookie-settings="cookie_settings.on = true" />
          </transition>
        </router-view>
      </main>

      <!-- ------------------------------------------------------------------------------------------------------------
        footer
      ------------------------------------------------------------------------------------------------------------ -->
      <bxs-footer
      ref="footer"
      class="bxs-app--footer"
      @click:cookie-settings="cookie_settings.on = true" />

      <!-- ------------------------------------------------------------------------------------------------------------
        big menu
      ------------------------------------------------------------------------------------------------------------ -->
      <transition
      mode="out-in"
      @enter="enterBigMenu"
      @leave="leaveBigMenu">
        <bxs-big-menu
        v-if="menu.on"
        ref="menu"
        class="bxs-app--big-menu" />
      </transition>

      <!-- ------------------------------------------------------------------------------------------------------------
        cookie banner
      ------------------------------------------------------------------------------------------------------------ -->
      <teleport to="#app">
        <bxs-cookie
        ref="cookie"
        v-show="!cookie_settings.on">
          <template #default="{ accept }">
            <div class="flex-1 text-ui">
              <div class="mb-8">
                <small class="text-medium">Notice!</small>
              </div>

              <div class="mb-ui small">
                <p class="mb-3">This website does not use profiling cookies. Instead, technical cookies, including third-party cookies, are used in order to improve the browsing experience.</p>
                <p class="mb-3">By clicking on the "Accept" button, you will close the banner without giving consent to any cookies except those that are necessary.</p>

                <div class="text-mute">
                  <router-link to="/privacy-policy" class="link-mute">privacy policy</router-link>
                  <span class="mx-2">-</span>
                  <router-link to="/cookie-policy" class="link-mute">cookie policy</router-link>
                </div>
              </div>

                <div class="text-right">
                  <!-- <bxs-btn
                  text
                  class="mr-ui"
                  @click="cookie_settings.on = true">
                    <small>Solo essenziali</small>
                  </bxs-btn> -->

                  <bxs-btn
                  text
                  class="mr-ui"
                  @click="cookie_settings.on = true">
                    <small>Imposta preferenze</small>
                  </bxs-btn>

                  <bxs-btn
                  color="white"
                  dark
                  @click="accept">
                    <small>Accetto</small>
                  </bxs-btn>
                </div>
            </div>
          </template>
        </bxs-cookie>
      </teleport>

      <!-- ------------------------------------------------------------------------------------------------------------
        cookie settings
      ------------------------------------------------------------------------------------------------------------ -->
      <bxs-cookie-settings
      v-model="cookie_settings.on"
      ga="G-WM79260NVP"
      @accept-all="$refs.cookie.accept()" />
    </div>

    <!-- ------------------------------------------------------------------------------------------------------------
      debug frame
    ------------------------------------------------------------------------------------------------------------ -->
    <div
    v-if="$route.query.debug"
    class="debug-frameview">
      <div></div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils/dom'
import { sleep } from '@/assets/libs/utils/promise'
import transitions from '@/assets/libs/transitions'
import Lenis from '@studio-freight/lenis'
import { gsap } from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

import Preloader from '@/components/core/Preloader.vue'
import Navframe from '@/components/core/layout/Navframe.vue'
import FooterComp from '@/components/core/layout/Footer.vue'
import BigMenu from '@/components/core/layout/BigMenu.vue'
import CookieBanner from '@/components/core/CookieBanner.vue'
import CookieSettings from '@/components/core/CookieSettings.vue'

gsap.registerPlugin(ScrollTrigger)

export default {
  name: 'App',
  components: {
    'bxs-preloader': Preloader,
    'bxs-navframe': Navframe,
    'bxs-footer': FooterComp,
    'bxs-big-menu': BigMenu,
    'bxs-cookie': CookieBanner,
    'bxs-cookie-settings': CookieSettings
  },
  data () {
    return {
      cookie_settings: {
        on: false
      },
      scroll: null,
      navframe: {
        is_hidden: false,
        is_actived: false,
        height: 0
      },
      window: {
        height: 0,
        width: 0
      },
      menu: {
        on: false
      }
    }
  },
  computed: {
    ...mapState({
      has_preloader: state => state.has_preloader,
      is_first_enter: state => state.is_first_enter,
      is_preloaded: state => state.is_preloaded,
      is_loading: state => state.is_loading,
      is_in_transition: state => state.is_in_transitions,
      is_custom_scroll: state => state.is_custom_scroll,
      resources: state => state.resources
    })
  },
  head () {
    const has_dynamic_seo = !!this.page && !!this.page.seo
    // const has_robots = has_dynamic_seo && !!this.page.seo.data.robots
    const has_og_img = has_dynamic_seo && this.page.seo.data && this.page.seo.data.og_image && this.page.seo.data.og_image.length > 0

    return {
      title: has_dynamic_seo ? this.page.seo.data.title : '',
      meta: [
        { name: 'description', content: has_dynamic_seo ? this.page.seo.data.description : '' },
        { name: 'article:modified_time', content: has_dynamic_seo ? this.page.seo.data.article_modified_time : '' },
        // og
        { name: 'og:type', content: has_dynamic_seo ? this.page.seo.data.og_type : '' },
        { name: 'og:site_name', content: has_dynamic_seo ? this.page.seo.data.title : '' },
        { name: 'og:title', content: has_dynamic_seo ? this.page.seo.data.og_description : '' },
        { name: 'og:description', content: has_dynamic_seo ? this.page.seo.data.title : '' },
        { name: 'og:image', content: has_og_img ? this.page.seo.data.og_image[0].url : '' },
        { name: 'og:url', content: window.location.href },
        // twc
        { name: 'twitter:card', content: has_dynamic_seo ? this.page.seo.data.twitter_card : '' },
        { name: 'twitter:title', content: has_dynamic_seo ? this.page.seo.data.og_description : '' },
        { name: 'twitter:description', content: has_dynamic_seo ? this.page.seo.data.title : '' },
        { name: 'twitter:image', content: has_og_img ? this.page.seo.data.og_image[0].url : '' },
        // indexing
        { name: 'robots', content: has_dynamic_seo ? `${this.page.seo.data.robots.index},${this.page.seo.data.robots.follow}` : '' }
      ],
      script: [
        { type: 'application/ld+json', content: has_dynamic_seo ? JSON.stringify(this.page.seo.data.schema) : '' }
      ]
    }
  },
  created () {
    console.log('app created()', this)

    this.$router.beforeEach((to, from, next) => {
      console.log('router internal beforeEach()')

      this.$store.commit('setIsFirstEnter', false)

      next()
    })
  },
  mounted () {
    console.log('app mounted()')

    this.$nextTick(this.start)
  },
  watch: {
    is_in_transition (newVal) {
      console.log('app watch is_in_transition', newVal)

      this[newVal ? 'pause' : 'play']()
    },
    'menu.on' (newVal) {
      console.log('app watch menu.on', newVal)

      this[newVal ? 'pause' : 'play']()
    }
  },
  methods: {
    // controls ------------------------------------------------------------------------------------------------------------
    async enterPreloader (el, next) {
      if (this.has_preloader) {
        await transitions.get('preloader')(el, 'in')
      }

      console.log('app preloading ...')
      await this.$refs.preloader.start()
      await sleep(1000)

      if (this.has_preloader) {
        await transitions.get('preloader')(el, 'out')
        // await tl.reverse()
      }

      await sleep(1000)

      this.$store.commit('setIsPreloaded', true)
      this.$store.commit('setIsInTransition', true)

      next()
    },
    async start () {
      console.log('app start()')

      window.scrollTo(0, 0)
      document.body.classList.toggle('bxs-lock-scroll')

      this.resize()
      window.addEventListener('resize', this.resize)

      window.addEventListener('online', evt => this.$eventHub.emit('change-connection', evt))
      window.addEventListener('offline', evt => this.$eventHub.emit('change-connection', evt))

      if (this.is_preloaded) {
        this.$store.commit('setIsInTransition', true)
      }
    },
    play () {
      this.$nextTick(() => {
        console.log('app play()', this.$refs)

        if (!this.scroll && this.is_custom_scroll) {
          this.scroll = new Lenis({
            // L'elemento che verrà utilizzato come contenitore di scorrimento
            // wrapper: this.$refs.app,
            // // L'elemento che contiene il contenuto che verrà fatto scorrere, in genere il figlio diretto del wrapper
            // content: this.$refs.wrapper,
            // The element that will listen to wheel and touch events
            eventsTarget: this.$refs.wrapper,
            lerp: 0.1,
            duration: 1,
            wheelMultiplier: 1,
            touchMultiplier: 1,
            easing: t => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
            // Resize instance automatically based on ResizeObserver. If false you must resize manually using .resize()
            autoResize: true,
            // Enable infinite scrolling!
            infinite: false,
            // Normalize wheel inputs across browsers (not reliable atm)
            normalizeWheel: true,
            orientation: 'vertical',
            gestureOrientation: 'vertical',
            // Whether or not to enable smooth scrolling for mouse wheel events
            smoothWheel: true,
            // Whether or not to enable smooth scrolling for touch events.
            smoothTouch: true,
            // Mimic touch device scroll while allowing scroll sync (can be unstable on iOS<16)
            syncTouch: true,
            // Lerp applied during syncTouch inertia
            syncTouchLerp: 0.1,
            // Manage the the strength of syncTouch inertia
            touchInertiaMultiplier: 35
          })

          // old stuff
          const raffaele = (time) => {
            this.scroll.raf(time)
            requestAnimationFrame(raffaele)
          }

          requestAnimationFrame(raffaele)

          document.body.classList.toggle('bxs-lock-scroll')

          // gsap.ticker.add((time) => {
          //   this.scroll.raf(time * 1000)
          // })

          this.scroll.on('scroll', this.handlerScroll)
        } else {
          this.scroll.start()
        }

        this.resize()
        this.updateCtas(true)
      })
    },
    pause () {
      console.log('app pause()')

      if (this.scroll) {
        // Pauses the scroll
        // this.scroll.stop()
      }
    },
    // handlers ------------------------------------------------------------------------------------------------------------
    handlerScroll (evt) {
      // console.log('app handlerScroll()', evt)

      ScrollTrigger.update()

      this.navframe.is_hidden = evt.direction === 1
      this.navframe.is_actived = evt.progress > 0.1
    },
    // fns ------------------------------------------------------------------------------------------------------------
    updateCtas (disabled = true) {
      const blanks = [...document.querySelectorAll(['a[target="_blank"]'])]
      blanks.forEach((el) => {
        if (!el.hasAttribute('rel')) el.setAttribute('rel', 'noopener')
      })

      const actions = [...document.querySelectorAll(['a button'])]
      actions.forEach((el) => {
        el.disabled = disabled
      })
    },
    resize () {
      if (this.$refs.navframe) this.navframe.height = this.$refs.navframe.$el.clientHeight

      this.window.height = window.innerHeight
      this.window.width = window.innerWidth

      this.$store.commit('resize')

      fixVh()

      if (this.scroll) {
        // Compute internal sizes, it has to be used if autoResize option is false.
        this.scroll.resize()
      }

      // Ricalcola il posizionamento di tutti gli ScrollTriggers sulla pagina; questo in genere avviene automaticamente quando la finestra/lo scroller si ridimensiona ma puoi forzarlo chiamando ScrollTrigger.refresh()
      ScrollTrigger.refresh()
      // Controlla dove si trova la barra di scorrimento e aggiorna di conseguenza i valori di avanzamento e direzione di tutte le istanze ScrollTrigger, controlla l'animazione (se necessario) e attiva i callback appropriati
      ScrollTrigger.update()
    },
    // transitions ------------------------------------------------------------------------------------------------------------
    async enterPage (el, next) {
      try {
        console.log('app enterPage()')

        const els = [
          el,
          this.$refs.navframe.$el,
          this.$refs.footer.$el
        ]

        if (this.scroll && this.scroll.scrollTo) {
          // scroll to top
          this.scroll.scrollTo(0, {
            duration: 0,
            immediate: true,
            lock: true
          })
        }

        // await sleep(10000)

        await transitions.get('fallback')(els, 'in')

        if (this.$route.meta.is_page && this.$store.state.page_enter !== this.$store.state.page_leave) {
          await this.$store.dispatch('getPage', this.$route.path === '/' ? 'home' : this.$route.path)
        }

        this.updateCtas(false)

        next()

        this.$store.commit('setIsInTransition', false)
        this.$store.commit('setIsLoading', false)
      } catch (err) {
        console.log('app enterPage() err', err)
        this.$router.push('/errors/404').catch(() => {})
      }
    },
    leavePage (el, next) {
      console.log('app leavePage()')

      this.$store.commit('setIsInTransition', true)
      this.updateCtas(true)

      const els = [
        el,
        this.$refs.navframe.$el,
        this.$refs.footer.$el
      ]

      transitions.get('fallback')(els, 'out').then(next)
    },
    enterBigMenu (el, next) {
      return transitions.get('big_menu')(el, 'in').then(next)
    },
    leaveBigMenu (el, next) {
      return transitions.get('big_menu')(el, 'out').then(() => {
        next()
      })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/styles/index.scss';
</style>