// import { getCurrentInstance } from 'vue'

const d = {
    textTruncate (v, l = 115) {
        if (!v) return v
        return v.substring(0, l) + '...'
    },
    textHighlight (v, k) {
        if (!v || !k) return v
        return v.replace(k, '<span class="bxs-text-highlight">' + k + '</span>')
    },
    wpTo(v, k = 'pathname') {
        const url = new URL(v)
        const is_internal = url.host.includes('customer.com')
        return is_internal ? url[k] : v
    }
}

export default d