import DataLazy from './core/DataLazy.vue'
import Logo from './core/Logo.vue'

import Layout from './core/layout/Layout.vue'
import Row from './core/layout/Row/Row.vue'
import Col from './core/layout/Col/Col.vue'

import Modal from './core/Modal.vue'
import Drawer from './core/Drawer.vue'
// import BottomNavigation from './core/BottomNavigation.vue'
// import TopNavigation from './core/TopNavigation.vue'
import Menu from './core/Menu.vue'
import List from './core/List.vue'
import ListItem from './core/ListItem.vue'
import CollapseItem from './core/CollapseItem.vue'
import Spacer from './core/Spacer.vue'
// import Toolbar from './core/Toolbar.vue'
// import Card from './core/Card.vue'
import Divider from './core/Divider.vue'

import Figure from './core/Figure.vue'
import Carousel from './core/Carousel.vue'

import Btn from './core/Btn.vue'
import Chip from './core/Chip.vue'
import ChipGroup from './core/ChipGroup.vue'
import Icon from './core/Icon.vue'

// import Alert from './Alert.vue'
import Loader from './core/Loader.vue'
// import Stepper from './Stepper.vue'

import Form from './core/form/Form'
import SwitchField from './core/form/SwitchField.vue'
import TextField from './core/form/TextField.vue'
import TextareaField from './core/form/TextareaField.vue'
import SelectField from './core/form/SelectField.vue'
// import NumberField from './core/form/NumberField.vue'

export {
    Btn,
    Carousel,
    Col,
    DataLazy,
    Divider,
    Drawer,
    Figure,
    Form,
    Icon,
    Layout,
    Loader,
    Logo,
    Row,
    SelectField,
    SwitchField,
    TextField,
    TextareaField,
    CollapseItem,
    List,
    ListItem,
    Menu,
    Spacer,
    Modal,
    Chip,
    ChipGroup
}

export default {
    Btn,
    Carousel,
    Col,
    DataLazy,
    Drawer,
    Divider,
    Figure,
    Form,
    Icon,
    Layout,
    Loader,
    Logo,
    Row,
    SelectField,
    SwitchField,
    TextField,
    TextareaField,
    CollapseItem,
    List,
    ListItem,
    Menu,
    Spacer,
    Modal,
    Chip,
    ChipGroup
}