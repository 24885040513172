<template>
    <router-link
    to="/"
    class="bxs-logo logo"
    :style="{ maxWidth: maxWidth }">
        <img
        :src="src"
        :alt="alt">
        <!-- <span class="fusco">FUSCO</span>
        <span class="express">EXPRESS</span> -->
    </router-link>
</template>

<script>
export default {
    name: 'bxs_logo',
    props: {
        'max-width': {
            type: String,
            required: false,
            default: '160px'
        },
        src: {
            type: String,
            required: false,
            default: '/img/logo-color.png'
        },
        alt: {
            type: String,
            required: false,
            default: 'Logo Fusco Express'
        }
    }
}
</script>

<style lang="scss" scoped>
a {
    display: inline-block;
    position: relative;
    text-decoration: none;
    vertical-align: middle;
    transform: translateY(-1px);

    &::after,
    &::before {
        display: none;
    }

    > img {
        display: block;
        position: relative;
        width: 100%;
        max-width: 100%;
        height: auto;
    }

    .fusco {
        font-family: 'effra-std-medium', sans-serif;
        letter-spacing: 2px;
        margin-right: 4px;
    }

    .express {
        font-family: 'effra-std-light', sans-serif;
        letter-spacing: 2px;
    }
}
</style>