<template>
    <bxs-layout class="bxs-data-lazy ma-0 pa-0">
        <transition
        name="lazy-trans"
        mode="out-in">
            <div
            v-if="loading"
            class="text-center pa-layout vue-content-placeholders-is-rounded vue-content-placeholders-is-animated">
                <div class="vue-content-placeholders-heading vue-content-placeholders-heading__title"></div>
                <div class="vue-content-placeholders-heading vue-content-placeholders-heading__subtitle"></div>
                <!-- <div class="vue-content-placeholders-text vue-content-placeholders-text__line" /> -->
            </div>

            <span v-else-if="!loading && data">
                <slot
                :data="data"
                :call="start"
                :error="error" />
            </span>

            <div
            v-else-if="!loading && error"
            class="pa-ui text-center">
                <p class="text-600s">Ops, errore {{ error.status_code }}</p>
                <p class="mb-6">{{ error.message || error.code || err.name || err }}</p>

                <div v-if="$slots.actions">
                    <slot name="error-actions" />
                </div>
            </div>
        </transition>
    </bxs-layout>
</template>

<script>
import { promiseAllProperties } from '@/assets/libs/utils/promise'

export default {
    name: 'bxs_data_lazy',
    props: {
        modelValue: {
            type: [Object, Array, Boolean, String],
            required: false,
            default: false
        },
        promises: {
            type: [Function, Object],
            required: false,
            default: null
        },
        'loading-message': {
            type: String,
            required: false,
            default: null
        },
        delay: {
            type: Number,
            required: false,
            default: 0
        }
    },
    emits: [
        'update:modelValue',
        'success',
        'error'
    ],
    data () {
        return {
            loading: true,
            data: null,
            error: null
        }
    },
    created () {
        this.start()
    },
    methods: {
        start () {
            this.data = null

            if (this.promises) {
                this.error = null

                if (typeof this.promises === 'object') {
                        promiseAllProperties(this.promises).then((res) => {
                        setTimeout(() => {
                            this.$emit('success', res)
                            this.data = res
                            this.loading = false

                            this.$emit('update:modelValue', res)
                        }, this.delay)
                    }).catch((err) => {
                        setTimeout(() => {
                            this.$emit('error', err)
                            this.error = err
                            this.loading = false
                        }, this.delay)
                    })
                } else {
                    this.promises().then((res) => {
                        setTimeout(() => {
                            this.$emit('success', res)
                            this.data = res
                            this.loading = false

                            this.$emit('update:modelValue', res)
                        }, this.delay)
                    }).catch((err) => {
                        setTimeout(() => {
                            this.$emit('error', err)
                            this.error = err
                            this.loading = false
                        }, this.delay)
                    })
                }
            }
        },
        play () {
            this.start()
        }
    }
}
</script>

<style lang="scss">
.lazy-trans-enter-active,
.lazy-trans-leave-active {
    transition: opacity 0.2s ease, trasform 0.2s ease;
}

.lazy-trans-enter-from,
.lazy-trans-leave-to {
    opacity: 0.1;
    transform: translateX(-5px);
}
</style>