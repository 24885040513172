<template>
    <div class="contacts--view">
        <hero />

        <section>
            <bxs-layout>
                <iframe
                v-if="$store.state.cookie_consent.gm"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2770.9198188773807!2d8.911567777588541!3d46.01279267108827!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4784320b249689d5%3A0x110c78beedeac63a!2sFusco%20Express%20Sagl!5e0!3m2!1sit!2sit!4v1716560979112!5m2!1sit!2sit"
                width="600"
                height="450"
                style="border:0;"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'contacts'
}
</script>
