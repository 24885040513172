<template>
    <div class="pa-ui small">
        <div class="mb-ui">
            <p class="text-mute mb-1">Descrizione del servizio</p>
            <p class="mb-0">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Minima in fuga magnam, sit excepturi nesciunt veritatis distinctio similique accusantium, dolorem exercitationem reprehenderit porro eum adipisci nemo commodi aperiam molestias placeat?</p>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Società di trasformazione</p>
            <p class="mb-0">Facebook Ireland Ltd. 4 Grand Canal Square Grand Canal Harbour Dublin 2 Ireland</p>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Responsabile della protezione dei dati della società di elaborazione</p>
            <p class="mb-0">Di seguito è possibile trovare l'indirizzo e-mail del responsabile del trattamento dei dati della società di elaborazione.</p>
            <a href="https://www.facebook.com/help/contact/540977946302970" target="_blank">https://www.facebook.com/help/contact/540977946302970</a>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Finalità dei dati</p>
            <p class="mb-2">Questa lista presenta gli scopi della raccolta e del trattamento dei dati.</p>
            <bxs-chip tile outlined small class="mr-2">Marketing</bxs-chip>
            <bxs-chip tile outlined small class="mr-2">Analytics</bxs-chip>
            <bxs-chip tile outlined small>Trasferimento internazionale di dati</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Tecnologie impiegate</p>
            <p class="mb-2">Questa lista presenta tutte le tecnologie utilizzate da questo servizio per raccogliere dati. Tecnologie tipiche sono i cookie e i pixel che vengono inseriti nel browser.</p>
            <bxs-chip tile outlined small class="mr-2">Cookies</bxs-chip>
            <bxs-chip tile outlined small>Da server a server</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Dati raccolti</p>
            <p class="mb-2">Questa lista presenta tutti i dati (personali) raccolti da o tramite l'utilizzo di questo servizio.</p>
            <bxs-chip tile outlined small class="mr-2 mb-1">Informazioni sull'ora (ad es. ora dell'evento)</bxs-chip>
            <bxs-chip tile small outlined>Informazioni sul negozio (ad es. Paese)</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Base giuridica</p>
            <p class="mb-2">Di seguito si elencano le basi giuridiche richieste per il trattamento dei dati.</p>
            <bxs-chip tile outlined small>Art. 6 (1) (a) GDPR (consenso)</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Luogo del trattamento</p>
            <p class="mb-2">È il luogo principale in cui vengono elaborati i dati raccolti. Qualora i dati vengano elaborati anche in altri paesi, all'utente viene fornita separatamente una comunicazione.</p>
            <bxs-chip tile outlined small>Stati Uniti e Irlanda / Unione Europea</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Periodo di conservazione</p>
            <p class="mb-1">Il periodo di conservazione è il periodo di tempo in cui i dati raccolti vengono salvati ai fini del trattamento. I dati devono essere eliminati una volta che non sono più necessari per gli scopi di elaborazione dichiarati.</p>
            <p class="mb-0">La durata dei cookies di Facebook Ads è fino a 90 giorni. Non appena la durata di un cookie è scaduta, il browser lo elimina automaticamente. Tutti i dati da noi condivisi con Facebook verranno conservati per un massimo di 2 anni. Archiviamo alcune delle informazioni relative alla tua interazione con i nostri annunci su Facebook per un massimo di 3 anni.</p>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Destinatari dei dati</p>
            <p class="mb-2">Di seguito sono elencati i destinatari dei dati raccolti.</p>
            <bxs-chip tile outlined small class="mr-2 mb-1">Facebook, Inc.</bxs-chip>
            <bxs-chip tile small outlined>Facebook Ireland Limited</bxs-chip>
        </div>

        <div class="mb-ui">
            <p class="text-mute mb-1">Fare clic qui per leggere l'informativa sulla privacy del servizio responsabile del trattamento dei dati</p>
            <a href="https://www.facebook.com/privacy/explanation" target="_blank">https://www.facebook.com/privacy/explanation</a>
        </div>

        <div>
            <p class="text-mute mb-1">Fare clic qui per disattivare questo responsabile del trattamento dei dati su tutti i domini</p>
            <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cookie_item_ga'
}
</script>