export function promiseAllProperties(promisesMap) {
    if (promisesMap === null || typeof promisesMap !== 'object') return Promise.reject(new TypeError('The input argument must be of type Object'))

    const keys = Object.keys(promisesMap)
    const promises = keys.map((key) => promisesMap[key])

    return Promise.all(promises).then(results => {
        return results.reduce((resolved, result, index) => {
            resolved[keys[index]] = result
            return resolved
        }, {})
    })
}

export const sleep = t => new Promise((resolve) => setTimeout(() => resolve(), t))

export default {
    promiseAllProperties,
    sleep
}