<template>
    <div class="errors-404--view">
        <section>
            <bxs-layout class="text-center">
                <h1>Error 404</h1>
                <p>Pagina non trovata o in costruzione</p>
                <btn to="/">Riparti</btn>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'error_404'
}
</script>