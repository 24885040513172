<template>
    <div :class="classes">
        <label>
            <!-- :disabled="inputableCacheDisabled" -->
            <!-- :autofocus="autofocus" -->
            <input
            type="checkbox"
            :name="name"
            :id="name"
            :checked="local_value"
            @change="handleChange($event.target.checked)">

            <!-- elevation_classes -->
            <span class="slider round">
                <bxs-icon
                v-show="local_value"
                name="check"
                width="12px"
                height="12px"
                :class="!disabled ? 'text-success' : 'text-black'"
                style="position: absolute; left: 5px; top: 4px;" />

                <bxs-icon
                v-show="!local_value"
                name="close"
                width="8px"
                height="8px"
                style="position: absolute; right: 7px; top: 6px;" />
            </span>
        </label>

        <span
        v-if="label"
        @click="handleChange(!local_value)"
        class="bxs-switch-field--label">{{ local_value ? (activedLabel || label) : label }}</span>
    </div>
</template>

<script>
export default {
    name: 'bxs-switch-field',
    props: {
        modelValue: {
            type: Boolean,
            required: false,
            default: false
        },
        name: {
            type: String,
            required: false,
            default: null
        },
        label: {
            type: String,
            required: false,
            default: null
        },
        'actived-label': {
            type: String,
            required: false,
            default: null
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        value: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: [
        'update:modelValue',
        'change'
    ],
    computed: {
        classes () {
            return [
                'bxs-field',
                'bxs-switch-field',
                {
                    'bxs-switch-field-checked': this.local_value,
                    'bxs-switch-field-disabled': this.local_value,
                    'bxs-disabled': this.disabled
                }
            ]
        }
    },
    data () {
        return {
            local_value: this.modelValue || this.value
        }
    },
    watch: {
        modelValue (newVal) {
            this.local_value = newVal
        }
    },
    methods: {
        handleChange (value) {
            this.local_value = value
            this.updateValue()
            this.$emit('change', this.local_value)
        },
        updateValue () {
            this.$emit('update:modelValue', this.local_value)
        },
        reset () {
            this.local_value = false
            this.updateValue()
        }
    }
}
</script>

<style lang="scss" scoped>
$bxs_swtich_width: var(--input-height);
$mrs_switch_height: calc($bxs_swtich_width / 2);
$bxs_switch_font_size_base: calc($mrs_switch_height / 1.5);

.bxs-switch-field {
    position: relative;
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    border: none;
    outline: none;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    margin-top: var(--input-margin-bottom);
    margin-bottom: var(--input-margin-bottom);

    &--label {
        color: var(--input-color-label);
        font-size: $bxs_switch_font_size_base;
        font-weight: 400;
        line-height: normal;
        padding-left: calc(var(--size-ui) / 2);
        margin-top: 1px;
        transition: color 0.2s ease;
    }

    &.bxs-switch-field-checked {
        > .bxs-switch-field--label {
            color: var(--switch-actived-pill-color);
        }

        .slider:before {
            background-color: var(--switch-actived-pill-color);
        }
    }

  &.bxs-disabled {
    cursor: not-allowed !important;

    > label {
      > span {
        background-color: var(--color-disabled) !important;

        &::before {
          background-color: var(--switch-pill-color-disabled) !important;
        }
      }
    }

    > .bxs-switch-field--label {
      color: var(--color-disabled) !important;
    }
  }
}

label {
    position: relative;
    display: inline-block;
    width: $bxs_swtich_width;
    height: $mrs_switch_height;
    font-size: $bxs_switch_font_size_base;
}

.bxs-switch-field input {
    padding: 0;
    margin: 0;
    opacity: 0;
    width: 0;
    height: 0;
}

// false bg
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--switch-background-color);
    transition: 0.25s ease;
    // border: 1px solid var(--switch-border-color);
}

// pallina
.slider:before {
    content: "";
    position: absolute;
    height: 75%;
    width: calc($bxs_swtich_width / 2.5);
    left: 10%; // calc(calc($resize / 2) + 1px);
    bottom: 10%;
    background-color: var(--switch-pill-color);
    transition: 0.25s ease;
}

// actived bg
input:checked + .slider {
    background-color: var(--switch-actived-background-color);
}

input:checked + .slider:before {
    transform: translateX(calc($bxs_swtich_width / 2 - 25%));
}

/* Rounded sliders */
.slider.round {
    border-radius: var(--pill);
}

.slider.round:before {
    border-radius: 50%;
}
</style>