<template>
    <div :class="['app-grid', { 'app-grid-light': light }]">
        <div :style="`background-image: url('${srcLeft}');`"></div>

        <div :style="`background-image: url('${srcCenter}');`">
            <div class="app-grid-line-l"></div>
            <div class="app-grid-line-r"></div>
            <div></div>
            <div></div>
        </div>

        <div :style="`background-image: url('${srcRight}');`"></div>
    </div>
</template>

<script>
export default {
    name: 'grid',
    props: {
        'src-left': String,
        'src-center': String,
        'src-right': String,
        light: Boolean
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

.app-grid {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100%;
    // height: calc(var(--vh, 1vh) * 100);
    pointer-events: none;
    display: none;
    flex-flow: row nowrap;
    z-index: var(--layer-grid);
    pointer-events: none;
    mix-blend-mode: multiply;

    @include mq(ds) {
        display: flex;
    }

    &.app-grid-light {
        > div {
            &:nth-child(2) {
                border-right-color: rgba(#fff, 0.25);
                border-left-color: rgba(#fff, 0.25);
            }
        }
    }

    > div {
        position: relative;
        height: 100%;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        // overflow: hidden;

        &:nth-child(2) {
            width: 50%;
            border-right: 1px solid rgba(#000, 0.25);
            border-left: 1px solid rgba(#000, 0.25);

            > div {
                position: absolute;
                top: 0;
                left: 0;
                width: 15px;
                height: 15px;
                background-color: var(--color-primary);
                border-radius: 1px;
                z-index: 1;

                &:last-child {
                    top: unset;
                    left: unset;
                    bottom: 0;
                    right: -7.5px;
                }
            }
        }

        &:first-child {
            width: 25%;
        }

        &:last-child {
            width: 25%;
        }

        &:last-child {
            top: var(--navbar-min-height);
            height: calc(100% - var(--navbar-min-height));
        }
    }
}
</style>