<template>
    <div
    :class="['hero-comp', {
        'hero-comp-centered': centered
    }]"
    :style="`background-image: linear-gradient(rgba(220, 13, 21, ${gradient}), rgba(220, 13, 21, ${gradient})), url('${src}');`">
        <img
        v-if="src"
        :src="src"
        :alt="alt">

        <div class="hero-comp--content">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'hero_comp',
    props: {
        src: String,
        alt: String,
        centered: Boolean,
        gradient: {
            type: String,
            required: false,
            default: '0'
        }
    }
}
</script>

<style lang="scss" scoped>
.hero-comp {
    position: relative;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background-color: var(--color-background);
    overflow: hidden;

    background-size: 95%;
    background-position: center right;
    background-repeat: no-repeat;
    // background-attachment: fixed;

    &.hero-comp-centered {
        .hero-comp--content {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    > img {
        width: 0px;
        height: 0px;
        visibility: hidden;
    }

    &--content {
        width: 100%;
        height: 100%;
        padding: var(--navbar-min-height) 0;
    }

    // &--img {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    //     height: 100%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     overflow: hidden;

    //     > img {
    //         max-width: 150%;
    //     }
    // }
}
</style>