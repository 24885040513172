import { gsap } from 'gsap'

const animations = new Map()

animations.set('fade-in', (el, val) => {
    const anim = gsap.from(el, {
        autoAlpha: 0,
        duration: 1,
        ease: 'expo.out',
        ...val
    })

    return anim
})

animations.set('big-scale', (el, val) => {
    const anim = gsap.to(el, {
        scale: 60,
        ...val
    })

    return anim
})

// ------------------------------------------------------------------------------------------------------

export default animations