<template>
    <div class="services--view">
        <hero />

        <section>
            <bxs-layout>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'services'
}
</script>
